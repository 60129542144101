import React from "react";
import { Modal, Button } from "react-bootstrap";

interface YesNoModalProps {
  YesNoModalClose: () => void;
  YesNoModalSubmit: () => void;
  yesnoTitle: string;
  yesnoQuestion: string;
  yesnoModalShow: boolean;
}

function YesNoModal(props: YesNoModalProps) {
  return (
    <Modal show={props.yesnoModalShow} onHide={props.YesNoModalClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{props.yesnoTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.yesnoQuestion}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.YesNoModalClose}>
          Close
        </Button>
        <Button variant="outline-success" onClick={props.YesNoModalSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default YesNoModal;
