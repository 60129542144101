import React from "react";
import { connect } from "react-redux";
import BootFooter from "../components/BootFooter";
import { Helmet } from "react-helmet";
import { store, getLoginActions } from "../reduxandotherstuff/arhs";
import * as constants from "../reduxandotherstuff/constants";
import NavBarCommon from "../components/NavBarCommon";
import type { NavBarHeaderProps } from "../components/NavBarCommon";

const headerItems: Array<NavBarHeaderProps> = [
  {
    isActive: true,
    href: "/",
    headerTitle: "Home",
    headerId: "home",
    dropdownitems: [],
  },
  {
    isActive: false,
    href: "/jobs",
    headerTitle: "Job Board",
    headerId: "jobboard",
    dropdownitems: [],
  },
];

interface Props {
  getlogininfo: any;
}

interface State {
  username: string;
  password: string;
  submitted: boolean;
}

class LoginPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // If the user has a 401 redirection from an open Bootstrap modal, things will appear greyed out.
  // So this removes the greyed out elements.

  componentDidMount() {
    let modalbackdrop = document.getElementsByClassName("modal-backdrop");
    if (modalbackdrop as HTMLCollectionOf<Element>) {
      for (var i = modalbackdrop.length - 1; i >= 0; i--) {
        if (modalbackdrop[0].parentNode as Element) {
          modalbackdrop[0].parentNode?.removeChild(modalbackdrop[0]);
        }
      }
    }
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    this.setState<never>({ [name]: value });
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    if (username && password) {
      store.dispatch<any>(getLoginActions.getLoginAction(username, password));
    }
  }

  render() {
    const { username, password, submitted } = this.state;

    let usernameClass = "form-control";
    if (submitted && !username) {
      usernameClass = "form-control is-invalid";
    }
    let passwordClass = "form-control";
    if (submitted && !password) {
      passwordClass = "form-control is-invalid";
    }

    return (
      <div>
        <Helmet>
          <title>Schedge | Login</title>
        </Helmet>
        <NavBarCommon headerItems={headerItems} />
        <main className="container" role="main">
          <div className="jumbotron row" id="loginjumbo">
            <div className="col-md-6 mx-auto" id="loginjumboleft">
              <h1>Welcome back.</h1>
              <p id="loginjumboleftpara">
                <img alt="Scaffold silhouette" src="/silhouettegreenbk.png" />
              </p>
            </div>
            <div className="col-md-6 mx-auto" id="loginjumboright">
              <h2>Sign in</h2>
              {this.props.getlogininfo.error && (
                <div className="alert alert-danger">
                  {constants.LOGIN_ERROR_MESSAGE}
                </div>
              )}
              <form className="login-form" onSubmit={this.handleSubmit}>
                <div className="form-group row">
                  <label htmlFor="username">User Name</label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className={usernameClass}
                    value={username}
                    onChange={this.handleChange}
                    placeholder="User Name"
                  />
                  <div className="invalid-feedback">
                    Please provide a valid user name.
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className={passwordClass}
                    value={password}
                    onChange={this.handleChange}
                    placeholder="Password"
                  />
                  <div className="invalid-feedback">
                    Please provide a valid password.
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-login float-right btn-primary"
                  id="btnSignIn"
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </main>
        <BootFooter />
      </div>
    );
  }
}

function mapStateToProps(state: Props) {
  return {
    getlogininfo: state.getlogininfo,
  };
}

export default connect(mapStateToProps)(LoginPage);
