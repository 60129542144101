import React from "react";
import { Container } from "react-bootstrap";

interface Props {
  footcontent: string;
}

class BootFooter extends React.Component<Props> {
  render() {
    return (
      <Container>
        <footer>{this.props.footcontent}</footer>
      </Container>
    );
  }
  static defaultProps = { footcontent: "© Schedge 2018–2021" };
}

export default BootFooter;
