import React, { useState, MouseEvent } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css"; // optional

interface TippyContextMenuItemProps {
  name: string;
  method: (argument: any) => void;
}

interface TippyContextMenuProps {
  children: React.ReactElement<any>;
  items: Array<TippyContextMenuItemProps>;
}

function TippyContextMenu(props: TippyContextMenuProps) {
  const [visible, setVisible] = useState(false);
  const [topbottom, setTopBottom] = useState(0);
  const [leftright, setLeftRight] = useState(0);
  const [dataElement, setDataElement] = useState<Element | null>(null);
  let getReferenceClientRect = () => ({
    width: 0,
    height: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });

  const show = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (e.target instanceof Element) {
      setDataElement(e.target);
    }
    setLeftRight(e.clientX);
    setTopBottom(e.clientY);
    getReferenceClientRect = () => ({
      width: 0,
      height: 0,
      top: e.clientY,
      bottom: e.clientY,
      left: e.clientX,
      right: e.clientX,
    });

    setVisible(true);
  };
  const hide = () => setVisible(false);
  const handleContextMenu = visible ? hide : show;

  return (
    <Tippy
      content={
        <div data-display="static" className="dropdown show">
          <div className="dropdown-menu show">
            {props.items.map(
              (item: TippyContextMenuItemProps, index: number) => (
                <button
                  key={index}
                  className="dropdown-item show dropdown-tippy"
                  onClick={(e: any) => {
                    hide();
                    item.method(dataElement);
                  }}
                >
                  {item.name}
                </button>
              )
            )}
          </div>
        </div>
      }
      offset={[topbottom, leftright]}
      arrow={false}
      interactive={true}
      placement={"right-start"}
      trigger="manual"
      visible={visible}
      onClickOutside={hide}
      getReferenceClientRect={getReferenceClientRect}
      theme={"light"}
      popperOptions={{ positionFixed: true, strategy: "fixed" } as any}
    >
      {React.cloneElement(props.children, { onContextMenu: handleContextMenu })}
    </Tippy>
  );
}

export default TippyContextMenu;
