import React from "react";
import BootFooter from "../components/BootFooter";
import { Helmet } from "react-helmet";
import type { NavBarHeaderProps } from "../components/NavBarCommon";
import NavBarCommon from "../components/NavBarCommon";
import SideMenu from "../components/SideMenu";
import JobList from "../components/JobList";
import type { SideMenuItemProp } from "../components/SideMenu";
import { connect } from "react-redux";
import { store, userService } from "../reduxandotherstuff/arhs";
import AddJobModal, {
  handleAddJobModalShow,
  handleAddJobModalClose,
  handleAddJobModalSubmit,
} from "../components/AddJobModal";
import type { GeoPosition } from "../reduxandotherstuff/commonfunc";
import type { AddJobModalCallerState } from "../components/AddJobModal";
import UpdateJobModal, {
  handleUpdateJobModalShow,
  handleUpdateJobModalClose,
  handleUpdateJobModalSubmit,
} from "../components/UpdateJobModal";
import type { UpdateJobModalCallerState } from "../components/UpdateJobModal";

import { Marker } from "react-leaflet";
import * as constants from "../reduxandotherstuff/constants";
import YesNoModal from "../components/YesNoModal";
import { history } from "../App";
const moment = require("moment");

interface Props {
  getjobsinfo: any;
  deletejobinfo: any;
}

interface State extends AddJobModalCallerState, UpdateJobModalCallerState {
  jobtodelete: string;
  deleteJobModalShow: boolean;
}

class PrivatePage extends React.Component<Props, State> {
  private refmarker = React.createRef<typeof Marker>();
  private draganddropFileInput = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      job_id: 0,
      jobname: "",
      client: "",
      description: "",
      submitted: false,
      errorMessage: "",
      addJobModalShow: false,
      startdate: moment().format("YYYY-MM-DD"),
      enddate: moment().format("YYYY-MM-DD"),
      autosign_workers: false,
      dist_autosign: 200,
      zoom: 13,
      draggable: true,
      center: {
        lat: constants.BRISBANE_LATITUDE,
        lng: constants.BRISBANE_LONGITUDE,
      },
      marker: {
        lat: constants.BRISBANE_LATITUDE,
        lng: constants.BRISBANE_LONGITUDE,
      },
      job_autosign: false,
      inspection_day_of_month: 7,
      autogen_inspections: false,
      jobtodelete: "",
      deleteJobModalShow: false,
      updateJobModalShow: false,
      possibleFileMembers: [],
    };

    this.handleAddJobModalShow = this.handleAddJobModalShow.bind(this);
    this.handleAddJobModalClose = this.handleAddJobModalClose.bind(this);
    this.handleAddJobModalSubmit = this.handleAddJobModalSubmit.bind(this);
    this.handleUpdateJobModalShow = this.handleUpdateJobModalShow.bind(this);
    this.handleUpdateJobModalClose = this.handleUpdateJobModalClose.bind(this);
    this.handleUpdateJobModalSubmit = this.handleUpdateJobModalSubmit.bind(
      this
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleAutosignChange = this.handleAutosignChange.bind(this);
    this.toggleDraggable = this.toggleDraggable.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.setMarker = this.setMarker.bind(this);
    this.handleJobAutosignChange = this.handleJobAutosignChange.bind(this);
    this.handleAutoGenInspectChange = this.handleAutoGenInspectChange.bind(
      this
    );
    this.removeJob = this.removeJob.bind(this);
    this.deleteJob = this.deleteJob.bind(this);
    this.deleteModalClose = this.deleteModalClose.bind(this);
    this.handleFileCheckChange = this.handleFileCheckChange.bind(this);
  }

  handleFileCheckChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.target;
    let updateindex = parseInt(name.slice(10, name.indexOf("-")));
    let possibleFileMembers = [...this.state.possibleFileMembers];
    let possibleFileMember = { ...possibleFileMembers[updateindex] };
    possibleFileMember.fileListMemberChecked = checked;
    possibleFileMembers[updateindex] = possibleFileMember;
    this.setState({ possibleFileMembers });
  }

  removeJob(jobid: string) {
    this.setState({ jobtodelete: jobid });
    this.setState({ deleteJobModalShow: true });
  }

  deleteModalClose() {
    this.setState({ deleteJobModalShow: false });
  }

  updatePosition(refmarker: React.RefObject<any>) {
    const marker = this.refmarker.current;
    this.setState({
      marker: marker.leafletElement.getLatLng(),
    });
  }

  setMarker(location: GeoPosition) {
    this.setState({ marker: location, center: location });
  }

  handleAutosignChange() {
    this.setState({
      autosign_workers: !this.state.autosign_workers,
    });
  }

  handleAutoGenInspectChange() {
    this.setState({
      autogen_inspections: !this.state.autogen_inspections,
    });
  }

  handleJobAutosignChange() {
    this.setState({
      job_autosign: !this.state.job_autosign,
    });
  }

  toggleDraggable() {
    this.setState({ draggable: !this.state.draggable });
  }

  handleAddJobModalShow() {
    handleAddJobModalShow(this);
  }

  handleAddJobModalClose() {
    handleAddJobModalClose(this, this.draganddropFileInput);
  }

  handleAddJobModalSubmit() {
    handleAddJobModalSubmit(this, this.draganddropFileInput);
  }

  handleUpdateJobModalShow(dataid: string) {
    handleUpdateJobModalShow(this, dataid);
  }

  handleUpdateJobModalClose() {
    handleUpdateJobModalClose(this, this.draganddropFileInput);
  }

  handleUpdateJobModalSubmit() {
    handleUpdateJobModalSubmit(this, this.draganddropFileInput);
  }

  handleChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) {
    const { name, value } = e.target;
    this.setState<never>({ [name]: value });
  }

  componentDidMount() {
    let localId = localStorage.getItem("userid") || "";
    store.dispatch<any>(userService.getJobs(localId));
    store.dispatch<any>(userService.getAssocUsers(localId));
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.getjobsinfo.items.length > 0 &&
      this.props.getjobsinfo.items.length !== prevProps.getjobsinfo.items.length
    ) {
    }
  }

  deleteJob() {
    this.setState({ deleteJobModalShow: false });
    let localId = localStorage.getItem("userid") || "";
    let p = store.dispatch<any>(
      userService.deleteJob(localId, this.state.jobtodelete)
    );
    p.then((value: any) => {
      history.go(0);
    });
  }

  render() {
    const headerItems: Array<NavBarHeaderProps> = [
      {
        isActive: false,
        href: "/",
        headerTitle: "Home",
        headerId: "home",
        dropdownitems: [],
      },
      {
        isActive: true,
        href: "/jobs",
        headerTitle: "Job Board",
        headerId: "jobboard",
        dropdownitems: [],
      },
      {
        isActive: false,
        href: "",
        headerTitle: "Add",
        headerId: "addjob",
        dropdownitems: [
          { navText: "Add Job", clickfunc: this.handleAddJobModalShow },
        ],
      },
    ];
    let maincontent = <p>This is some main content.</p>;
    let sidemenu: Array<SideMenuItemProp> = [
      {
        icon: "add_circle_outline",
        text: "",
        enabling: true,
        circlearound: true,
        hrefval: ".",
        onclick: this.handleAddJobModalShow,
      },
      {
        icon: "dashboard",
        text: "Job Board",
        enabling: true,
        hrefval: "/jobs",
      },
      {
        icon: "query_builder",
        text: "Schedule",
        enabling: false,
        hrefval: "/schedule",
      },
      {
        icon: "list",
        text: "Newsfeed",
        enabling: false,
        hrefval: "/newsfeed",
      },
      {
        icon: "",
        text: "",
        enabling: false,
        hrefval: "",
      },
      {
        icon: "notifications",
        text: "Notifications",
        enabling: false,
        hrefval: "/notifications",
      },
      {
        icon: "settings",
        text: "Settings",
        enabling: false,
        hrefval: "/settings",
      },
    ];

    const noTotalJobs =
      this.props.getjobsinfo && this.props.getjobsinfo.items
        ? this.props.getjobsinfo.items.length
        : 0;
    let noActiveJobs = 0;
    let contractedHours = 0;
    let inspectionsDue = 0;
    let activeJobs = [];
    let activeJobTable = [];
    if (this.props.getjobsinfo && this.props.getjobsinfo.items) {
      activeJobs = this.props.getjobsinfo.items.filter((x: any) => {
        return !x.completed;
      });
      noActiveJobs = activeJobs.length;
      const totalHoursreducer = (accumulator: number, currentValue: any) => {
        return accumulator + parseInt(currentValue.total_hours);
      };
      const inspectionsReducer = (accumulator: number, currentValue: any) => {
        if (["O", "D"].includes(currentValue.is_due)) {
          return accumulator + 1;
        }
        return accumulator;
      };
      contractedHours = this.props.getjobsinfo.items.reduce(
        totalHoursreducer,
        0
      );
      inspectionsDue = this.props.getjobsinfo.items.reduce(
        inspectionsReducer,
        0
      );
      for (let item of activeJobs) {
        activeJobTable.push([
          item.jobname,
          item.total_variations,
          "generateDueImage()",
          "generateFakeDot(item.id, props.navigation)",
        ]);
      }
    }
    maincontent = (
      <>
        <div className="row">
          <div className="col-sm-12">
            <div className="card topcard">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">Total Jobs</h2>
                        <p className="card-text qtacpa">{noTotalJobs}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">Active Jobs</h2>
                        <p className="card-text qtacpa">{noActiveJobs}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">Contracted Hours</h2>
                        <p className="card-text qtacpa">{contractedHours}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">Inspections Due</h2>
                        <p className="card-text qtacpa">{inspectionsDue}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p>&nbsp;</p>
        <JobList
          jobListItem={this.props.getjobsinfo.items}
          removeJob={this.removeJob}
          handleUpdateJobModalShow={this.handleUpdateJobModalShow}
        />
      </>
    );

    return (
      <div>
        <Helmet>
          <title>Schedge | Job Board</title>
        </Helmet>
        <NavBarCommon headerItems={headerItems} />
        <main className="container-fluid" role="main">
          <div className="row">
            <div className="col-md-2">
              <p>&nbsp;</p>
              <SideMenu entries={sidemenu} />
            </div>
            <div className="col-md-10">
              <h1>Job Board</h1>
              {maincontent}
            </div>
          </div>
        </main>
        <BootFooter />
        <AddJobModal
          jobname={this.state.jobname}
          client={this.state.client}
          description={this.state.description}
          submitted={this.state.submitted}
          startdate={this.state.startdate}
          enddate={this.state.enddate}
          errorMessage={this.state.errorMessage}
          addJobModalShow={this.state.addJobModalShow}
          autosign_workers={this.state.autosign_workers}
          dist_autosign={this.state.dist_autosign}
          handleAddJobModalClose={this.handleAddJobModalClose}
          handleAddJobModalSubmit={this.handleAddJobModalSubmit}
          handleChange={this.handleChange}
          handleAutosignChange={this.handleAutosignChange}
          center={this.state.center}
          marker={this.state.marker}
          zoom={this.state.zoom}
          draggable={this.state.draggable}
          refmarker={this.refmarker}
          toggleDraggable={this.toggleDraggable}
          updatePosition={this.updatePosition}
          setMarker={this.setMarker}
          handleJobAutosignChange={this.handleJobAutosignChange}
          job_autosign={this.state.job_autosign}
          inspection_day_of_month={this.state.inspection_day_of_month}
          autogen_inspections={this.state.autogen_inspections}
          handleAutoGenInspectChange={this.handleAutoGenInspectChange}
          draganddropFileInput={this.draganddropFileInput}
        />
        <UpdateJobModal
          job_id={this.state.job_id}
          jobname={this.state.jobname}
          client={this.state.client}
          description={this.state.description}
          submitted={this.state.submitted}
          startdate={this.state.startdate}
          enddate={this.state.enddate}
          errorMessage={this.state.errorMessage}
          updateJobModalShow={this.state.updateJobModalShow}
          autosign_workers={this.state.autosign_workers}
          dist_autosign={this.state.dist_autosign}
          handleUpdateJobModalClose={this.handleUpdateJobModalClose}
          handleUpdateJobModalSubmit={this.handleUpdateJobModalSubmit}
          handleChange={this.handleChange}
          handleAutosignChange={this.handleAutosignChange}
          center={this.state.center}
          marker={this.state.marker}
          zoom={this.state.zoom}
          draggable={this.state.draggable}
          refmarker={this.refmarker}
          toggleDraggable={this.toggleDraggable}
          updatePosition={this.updatePosition}
          setMarker={this.setMarker}
          handleJobAutosignChange={this.handleJobAutosignChange}
          job_autosign={this.state.job_autosign}
          inspection_day_of_month={this.state.inspection_day_of_month}
          autogen_inspections={this.state.autogen_inspections}
          handleAutoGenInspectChange={this.handleAutoGenInspectChange}
          draganddropFileInput={this.draganddropFileInput}
          possibleFileMembers={this.state.possibleFileMembers}
          handleFileCheckChange={this.handleFileCheckChange}
        />
        <YesNoModal
          yesnoTitle="Delete"
          yesnoQuestion="Do you wish to delete this job?"
          yesnoModalShow={this.state.deleteJobModalShow}
          YesNoModalSubmit={this.deleteJob}
          YesNoModalClose={this.deleteModalClose}
        />
      </div>
    );
  }
}

function mapStateToProps(state: Props) {
  return {
    getjobsinfo: state.getjobsinfo,
  };
}

export default connect(mapStateToProps)(PrivatePage);
