import React from "react";
import TippyContextMenu from "./TippyContextMenu";

interface JobListItemProps {
  id: string;
  jobname: string;
  total_variations: number;
  total_inspections: number;
  succ_inspections: number;
  is_due: string;
  removeJob: (jobid: string) => void;
  handleUpdateJobModalShow: (dataid: string) => void;
}

function JobListItem(props: JobListItemProps) {
  let anchor = `/jobs/${props.id}`;
  let dueImage = <></>;
  switch (props.is_due) {
    case "O":
      dueImage = <img alt="Overdue" src="/reddot.jpg" />;
      break;
    case "C":
      dueImage = <img alt="Complete" src="/greendot.jpg" />;
      break;
    case "N":
      dueImage = <img alt="Not due" src="/bluedot.jpg" />;
      break;
    case "D":
      dueImage = <img alt="Due" src="/orangedot.jpg" />;
      break;
  }
  let context_id = "context_id_" + props.id;
  let jobListContextMenu = [
    {
      name: "Update",
      method: (e: any) => {
        props.handleUpdateJobModalShow(e.getAttribute("data-id"));
      },
    },
    {
      name: "Delete",
      method: (e: any) => {
        props.removeJob(e.getAttribute("data-id"));
      },
    },
  ];

  return (
    <TippyContextMenu items={jobListContextMenu}>
      <tr
        className="plan-table-tr jobListItem"
        data-id={props.id}
        id={context_id}
      >
        <th scope="row" className="project-name-col" data-id={props.id}>
          <a data-id={props.id} href={anchor}>
            {props.jobname}
          </a>
        </th>

        <td data-id={props.id}>{props.total_variations}</td>
        <td data-id={props.id}>
          {props.succ_inspections}/{props.total_inspections}
        </td>
        <td data-id={props.id}>{dueImage}</td>
      </tr>
    </TippyContextMenu>
  );
}

interface JobListProps {
  jobListItem: Array<JobListItemProps>;
  removeJob: (jobid: string) => void;
  handleUpdateJobModalShow: (dataid: string) => void;
}

function JobList(props: JobListProps) {
  const jobListItems = props.jobListItem.map((item) => (
    <JobListItem
      id={item.id}
      jobname={item.jobname}
      total_variations={item.total_variations}
      total_inspections={item.total_inspections}
      succ_inspections={item.succ_inspections}
      is_due={item.is_due}
      key={item.id}
      removeJob={props.removeJob}
      handleUpdateJobModalShow={props.handleUpdateJobModalShow}
    />
  ));
  return (
    <div>
      <table className="table table-hover plan-table">
        <thead className="plan-table-thead">
          <tr className="plan-table-tr">
            <th className="plan-table-th col">Job name</th>
            <th className="plan-table-th col">Variations</th>
            <th className="plan-table-th col">Inspections</th>
            <th className="plan-table-th col">Status</th>
          </tr>
        </thead>
        <tbody className="plan-table-tbody">{jobListItems}</tbody>
      </table>
      <div id="canv-invisible" />
    </div>
  );
}

export default JobList;
export type { JobListProps };
