import React from "react";
import schedge from "../schedge_g_w_40.png";
import { connect } from "react-redux";
import {
  printAltTeamMemberName,
  printableTimeElapsed,
  extractContent,
  ellipsisizer,
} from "../reduxandotherstuff/commonfunc";
import NavNotifier from "./NavNotifier";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { store, userService } from "../reduxandotherstuff/arhs";
import SmartAvatar from "./SmartAvatar";

interface NavBarCommonProps {
  getuserinfo: any;
  getuseridinfo: any;
  getassocusersinfo: any;
  getunreadmsgsinfo: any;
  getunconfirmedsinfo: any;
  getconfirmunknownsinfo: any;
  getchannelsinfo: any;
  headerItems: Array<NavBarHeaderProps>;
}

interface DropDownNavBarItemProps {
  href?: string | undefined;
  clickfunc?: (event: any) => void | undefined;
  navText: string;
}

class DropDownNavBarItem extends React.Component<DropDownNavBarItemProps> {
  render() {
    if (this.props.href) {
      return (
        <NavDropdown.Item href={this.props.href} onClick={this.props.clickfunc}>
          {this.props.navText}
        </NavDropdown.Item>
      );
    } else {
      return (
        <NavDropdown.Item onClick={this.props.clickfunc}>
          {this.props.navText}
        </NavDropdown.Item>
      );
    }
  }
}

interface NavBarHeaderProps {
  dropdownitems: Array<DropDownNavBarItemProps>;
  headerTitle: string;
  headerId: string;
  isActive: boolean;
  href: string;
}

class NavBarHeader extends React.Component<NavBarHeaderProps> {
  render() {
    if (this.props.dropdownitems.length) {
      const navbarItems = this.props.dropdownitems.map(
        (item: DropDownNavBarItemProps, index: number) => (
          <DropDownNavBarItem
            href={item.href}
            navText={item.navText}
            clickfunc={item.clickfunc}
            key={index}
          />
        )
      );
      return (
        <NavDropdown
          title={this.props.headerTitle}
          active={this.props.isActive}
          id={this.props.headerId}
        >
          {navbarItems}
        </NavDropdown>
      );
    } else {
      return (
        <Nav.Link
          id={this.props.headerId}
          active={this.props.isActive}
          href={this.props.href}
        >
          {this.props.headerTitle}
        </Nav.Link>
      );
    }
  }
}

class NavBarCommon extends React.Component<NavBarCommonProps> {
  componentDidMount() {
    let localId = localStorage.getItem("userid") || "";
    if (localId) {
      store.dispatch<any>(userService.getUnreadMsgs(localId));
      store.dispatch<any>(userService.getUnconfirmeds(localId));
      store.dispatch<any>(userService.getConfirmUnknowns(localId));
    }
  }

  render() {
    const headeritems = this.props.headerItems.map((item, index) => (
      <NavBarHeader
        dropdownitems={item.dropdownitems}
        headerTitle={item.headerTitle}
        headerId={item.headerId}
        isActive={item.isActive}
        href={item.href}
        key={index}
      />
    ));

    let logoutButton = <></>;
    if (this.props.getuseridinfo.items && this.props.getuserinfo.item) {
      let notifierItems = [];

      if (
        this.props.getassocusersinfo.items &&
        this.props.getunreadmsgsinfo.items &&
        this.props.getchannelsinfo.items
      ) {
        for (let item of this.props.getunreadmsgsinfo.items) {
          let itemHref = "";
          let commentText = <></>;
          let content = item.subject;
          if (!content) {
            content = extractContent(item.message_content);
          }
          content = ellipsisizer(content, 20);
          if (item.channel !== null) {
            itemHref = `/newsfeed/channel/${item.channel}`;

            let selectedChannel = this.props.getchannelsinfo.items.find(
              (x: any) => {
                return x.id === item.channel;
              }
            );
            if (selectedChannel) {
              commentText = (
                <>
                  {" "}
                  messaged you in the <strong>
                    {selectedChannel.title}
                  </strong>{" "}
                  channel: <em>{content}</em>.
                </>
              );
            } else {
              commentText = (
                <>
                  {" "}
                  messaged you in a <strong>channel</strong>: <em>{content}</em>
                  .
                </>
              );
            }
          } else {
            itemHref = `/newsfeed/dms/${item.author}`;
            commentText = (
              <>
                {" "}
                messaged <strong>you</strong>: <em>{content}</em>.
              </>
            );
          }
          let itemuser = this.props.getassocusersinfo.items.find((x: any) => {
            return x.id === item.author;
          });
          if (itemuser) {
            let creationTime = printableTimeElapsed(item.created_at);
            notifierItems.push({
              href: itemHref,
              imgSource: itemuser.extraprofile.avatar,
              first_name: itemuser.first_name,
              last_name: itemuser.last_name,
              username: itemuser.username,
              teamMemberName: printAltTeamMemberName(itemuser),
              textStuff: commentText,
              timeStuff: creationTime,
            });
          }
        }
      }

      if (
        this.props.getassocusersinfo.items &&
        this.props.getunconfirmedsinfo.items &&
        this.props.getchannelsinfo.items
      ) {
        for (let item of this.props.getunconfirmedsinfo.items) {
          let itemHref = "";
          let commentText = <></>;
          let content = item.subject;
          if (!content) {
            content = extractContent(item.message_content);
          }
          content = ellipsisizer(content, 20);
          if (item.channel !== null) {
            itemHref = `/newsfeed/channel/${item.channel}`;

            let selectedChannel = this.props.getchannelsinfo.items.find(
              (x: any) => {
                return x.id === item.channel;
              }
            );
            if (selectedChannel) {
              commentText = (
                <>
                  {" "}
                  wants you to confirm you have read their message in the{" "}
                  <strong>{selectedChannel.title}</strong> channel:{" "}
                  <em>{content}</em>.
                </>
              );
            } else {
              commentText = (
                <>
                  {" "}
                  wants you to confirm you have read their message in a{" "}
                  <strong>channel</strong>: <em>{content}</em>.
                </>
              );
            }
          } else {
            itemHref = `/newsfeed/dms/${item.author}`;
            commentText = (
              <>
                {" "}
                wants <strong>you</strong> to confirm you have read their
                message: <em>{content}</em>.
              </>
            );
          }
          let itemuser = this.props.getassocusersinfo.items.find((x: any) => {
            return x.id === item.author;
          });
          if (itemuser) {
            let creationTime = printableTimeElapsed(item.created_at);
            notifierItems.push({
              href: itemHref,
              imgSource: itemuser.extraprofile.avatar,
              first_name: itemuser.first_name,
              last_name: itemuser.last_name,
              username: itemuser.username,
              teamMemberName: printAltTeamMemberName(itemuser),
              textStuff: commentText,
              timeStuff: creationTime,
            });
          }
        }
      }

      if (
        this.props.getassocusersinfo.items &&
        this.props.getconfirmunknownsinfo.items &&
        this.props.getchannelsinfo.items
      ) {
        for (let item of this.props.getconfirmunknownsinfo.items) {
          for (let confirmedread of item.targets_confirm) {
            let itemHref = "";
            let commentText = <></>;
            let content = item.subject;
            if (!content) {
              content = extractContent(item.message_content);
            }
            content = ellipsisizer(content, 20);
            if (item.channel !== null) {
              itemHref = `/newsfeed/channel/${item.channel}`;

              let selectedChannel = this.props.getchannelsinfo.items.find(
                (x: any) => {
                  return x.id === item.channel;
                }
              );
              if (selectedChannel) {
                commentText = (
                  <>
                    {" "}
                    has confirmed they have read your message in the{" "}
                    <strong>{selectedChannel.title}</strong> channel:{" "}
                    <em>{content}</em>.
                  </>
                );
              } else {
                commentText = (
                  <>
                    {" "}
                    has confirmed they have read your message in a{" "}
                    <strong>channel</strong>: <em>{content}</em>.
                  </>
                );
              }
            } else {
              itemHref = `/newsfeed/dms/${confirmedread}`;
              commentText = (
                <>
                  {" "}
                  has confirmed they have read <strong>
                    your
                  </strong> message: <em>{content}</em>.
                </>
              );
            }
            let itemuser = this.props.getassocusersinfo.items.find((x: any) => {
              return x.id === confirmedread;
            });
            if (itemuser) {
              let creationTime = printableTimeElapsed(item.created_at);
              notifierItems.push({
                href: itemHref,
                imgSource: itemuser.extraprofile.avatar,
                first_name: itemuser.first_name,
                last_name: itemuser.last_name,
                username: itemuser.username,
                teamMemberName: printAltTeamMemberName(itemuser),
                textStuff: commentText,
                timeStuff: creationTime,
              });
            }
          }
        }
      }

      logoutButton = (
        <ul className="navbar-nav navbar-right">
          <NavNotifier items={notifierItems} />
          <li className="nav-item">
            <a href="/settings" className="nav-link">
              <div className="inset">
                <SmartAvatar
                  src={this.props.getuserinfo.item.extraprofile.avatar}
                  username={this.props.getuserinfo.item.username}
                  first_name={this.props.getuserinfo.item.first_name}
                  last_name={this.props.getuserinfo.item.last_name}
                />
              </div>
            </a>
          </li>
          <li className="nav-item">
            <a href="/logout" className="nav-link">
              Log Out
            </a>
          </li>
        </ul>
      );
    }

    return (
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/">
          <img src={schedge} alt="Schedge" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">{headeritems}</Nav>
          {logoutButton}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

function mapStateToProps(state: NavBarCommonProps) {
  return {
    getuserinfo: state.getuserinfo,
    getuseridinfo: state.getuseridinfo,
    getassocusersinfo: state.getassocusersinfo,
    getunreadmsgsinfo: state.getunreadmsgsinfo,
    getchannelsinfo: state.getchannelsinfo,
    getunconfirmedsinfo: state.getunconfirmedsinfo,
    getconfirmunknownsinfo: state.getconfirmunknownsinfo,
  };
}

export default connect(mapStateToProps)(NavBarCommon);
export type { NavBarHeaderProps };
