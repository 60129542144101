import React from "react";

interface SideMenuItemProp {
  text: string;
  icon: string;
  enabling: boolean;
  hrefval: string;
  circlearound?: boolean;
  onclick?: () => void;
}

const SideMenuItem = ({
  text,
  icon,
  enabling,
  hrefval,
  circlearound,
  onclick = undefined,
}: SideMenuItemProp) =>
  text || circlearound ? (
    <div className={circlearound ? "circlearound row" : "row"}>
      <h4 className="col-md-3 SideMenu-icon-cont">
        <i
          className={
            enabling
              ? "SideMenu-enab material-icons-outlined md-48"
              : "SideMenu-disab material-icons-outlined md-48"
          }
        >
          {onclick && hrefval ? (
            <a onClick={onclick}>{icon}</a>
          ) : hrefval ? (
            <a href={hrefval}>{icon}</a>
          ) : (
            <>{icon}</>
          )}
        </i>
      </h4>
      <h5
        className={
          enabling ? "SideMenu-enab col-md-9" : "SideMenu-disab col-md-9"
        }
      >
        {onclick && hrefval ? (
          <a onClick={onclick}>{text}</a>
        ) : hrefval ? (
          <a href={hrefval}>{text}</a>
        ) : (
          <>{text}</>
        )}
      </h5>
    </div>
  ) : (
    <hr />
  );

interface SideMenuProp {
  entries: Array<SideMenuItemProp>;
}

class SideMenu extends React.Component<SideMenuProp> {
  render() {
    let numbers = Array.from(
      { length: this.props.entries.length },
      (v, k) => k + 1
    );

    let sidemenuitems = numbers.map((number) =>
      this.props.entries[number - 1] ? (
        <SideMenuItem
          key={number.toString()}
          icon={this.props.entries[number - 1].icon}
          text={this.props.entries[number - 1].text}
          enabling={this.props.entries[number - 1].enabling}
          hrefval={this.props.entries[number - 1].hrefval}
          circlearound={this.props.entries[number - 1].circlearound}
          onclick={this.props.entries[number - 1].onclick}
        />
      ) : (
        <hr key={number.toString()} />
      )
    );

    return <>{sidemenuitems}</>;
  }
}

export default SideMenu;
export type { SideMenuItemProp };
