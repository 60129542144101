import React from "react";
import * as constants from "../reduxandotherstuff/constants";
const moment = require("moment");

type GeoPosition = { lat: number; lng: number };

interface TeamMember {
  teamMemberName: string;
  teamMemberId: number;
  teamMemberChecked: boolean;
  teamMemberFirstName: string;
  teamMemberLastName: string;
  teamMemberAvatarPath: string;
}

interface TeamSubMember {
  teamMemberName: string;
  teamMemberFirstName: string;
  teamMemberLastName: string;
}

interface altTeamSubMember {
  first_name: string;
  last_name: string;
  username: string;
}

function printTeamMemberName(tm: TeamSubMember) {
  if (tm.teamMemberFirstName && tm.teamMemberLastName) {
    return tm.teamMemberFirstName + " " + tm.teamMemberLastName;
  } else if (tm.teamMemberFirstName) {
    return tm.teamMemberFirstName;
  } else if (tm.teamMemberLastName) {
    return tm.teamMemberLastName;
  } else {
    return tm.teamMemberName;
  }
}

function printAltTeamMemberName(atm: altTeamSubMember) {
  return printTeamMemberName({
    teamMemberName: atm.username,
    teamMemberFirstName: atm.first_name,
    teamMemberLastName: atm.last_name,
  });
}

interface FileListMember {
  fileListMemberName: string;
  fileListMemberId: number;
  fileListMemberChecked: boolean;
}

function prettifyErrorAlt(
  message: string,
  keystocontrols: { [key: string]: string },
  anticiperrors: { [key: string]: string }
) {
  let jsonmessage: any;
  let errorsold, errors;
  try {
    jsonmessage = JSON.parse(message);
  } catch (e) {
    return <p>{message}</p>;
  }

  if (typeof jsonmessage === "string") {
    return <p>{jsonmessage}</p>;
  }
  let prettyField = (fieldname: string, issue: string) =>
    "There are issues with the value provided for the " +
    fieldname +
    " field: " +
    (anticiperrors[issue] ? anticiperrors[issue] : "'" + issue + "'") +
    ".";
  errorsold = Object.keys(jsonmessage).map(function (key) {
    return [key, jsonmessage[key]];
  });

  let errorsnew = [];
  for (let i = 0; i < errorsold.length; i++) {
    if (errorsold[i][0] !== "extraprofile") {
      errorsnew.push(errorsold[i]);
    } else {
      let extraprofileerrors = Object.keys(errorsold[i][1]).map(function (key) {
        return [key, jsonmessage["extraprofile"][key]];
      });
      for (let j = 0; j < extraprofileerrors.length; j++) {
        errorsnew.push(extraprofileerrors[j]);
      }
    }
  }

  errors = errorsnew.map((item: Array<string>, i: number) => (
    <li key={i}>
      {!keystocontrols.hasOwnProperty(item[0])
        ? item[1]
        : prettyField(keystocontrols[item[0]], item[1])}
    </li>
  ));
  return <ul>{errors}</ul>;
}

// Expects SRIDS of the form: `SRID=${a};POINT (${b} ${c})` and returns [a, b, c]

function parseSRID(sridString: string) {
  const SRIDString = "SRID=";
  const PointString = ";POINT (";
  const PointEndString = ")";
  let SRIDStart = sridString.indexOf(SRIDString);
  let PointStart = sridString.indexOf(PointString);
  let PointEnd = sridString.indexOf(PointEndString);
  let SRIDPart = sridString.substr(
    SRIDStart + SRIDString.length,
    PointStart - SRIDString.length
  );
  let PointPart = sridString.substr(
    PointStart + PointString.length,
    PointEnd - PointStart - PointString.length
  );
  let PointBits = PointPart.split(" ");
  return [
    parseInt(SRIDPart),
    parseFloat(PointBits[0]),
    parseFloat(PointBits[1]),
  ];
}

function printableTimeElapsed(timeval: string) {
  let diffElapseDays = moment().diff(moment(timeval), "days");
  if (diffElapseDays === 0) {
    let diffElapseHours = moment().diff(moment(timeval), "hours");
    if (diffElapseHours === 0) {
      let diffElapseMinutes = moment().diff(moment(timeval), "minutes");
      return `${diffElapseMinutes} minutes ago`;
    }
    return `${diffElapseHours} hours ago`;
  } else {
    return moment(timeval).format(constants.COMMON_DATE_FORMAT);
  }
}

// Quick and dirty way of getting text from HTML. From:
// https://stackoverflow.com/questions/28899298/extract-the-text-out-of-html-string-using-javascript

function extractContent(html: string) {
  return new DOMParser().parseFromString(html, "text/html").documentElement
    .textContent;
}

// What if the content is a little too long to present? Then trim it to a length, and add a "..."
// character after it? Maxlength is the maximum size of the result ("..." included)

function ellipsisizer(inputStr: string, maxLength: number) {
  if (inputStr.length <= maxLength) {
    return inputStr;
  } else if (inputStr.length < maxLength + 3) {
    return inputStr.substr(0, inputStr.length - 3) + "...";
  }
  return inputStr.substr(0, maxLength - 3) + "...";
}

export {
  prettifyErrorAlt,
  parseSRID,
  printTeamMemberName,
  printAltTeamMemberName,
  printableTimeElapsed,
  extractContent,
  ellipsisizer,
};
export type { TeamMember, FileListMember, GeoPosition };
