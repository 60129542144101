import React from "react";
import Avatar from "react-avatar";
import { externalDomain } from "../reduxandotherstuff/env";

interface Props {
  src: string;
  first_name: string;
  last_name: string;
  username: string;
}

function SmartAvatar(props: Props) {
  if (props.src) {
    let avatarurl = externalDomain + props.src;
    return <Avatar round={true} size="30" src={avatarurl} />;
  } else if (props.first_name || props.last_name) {
    let avatarname = (props.first_name + " " + props.last_name).trim();
    return <Avatar round={true} size="30" name={avatarname} />;
  } else {
    return <Avatar round={true} size="30" name={props.username} />;
  }
}

export default SmartAvatar;
