// Constants.

export const LOGIN_ERROR_MESSAGE =
  "You were not able to log in with the user name and password provided.";

// Default location for Add Job - the centre of Brisbane.

export const BRISBANE_LATITUDE = -27.467778;
export const BRISBANE_LONGITUDE = 153.028056;

// "Nicer" common format for printing DATES and DATETIMES

export const COMMON_DATE_FORMAT = "MMMM Do YYYY";
export const COMMON_DATETIME_FORMAT = "MMMM Do YYYY, h:mm:ss a";
