import React from "react";
import { NavDropdown } from "react-bootstrap";
import SmartAvatar from "../components/SmartAvatar";

interface NavNotifierProps {
  items: Array<NavNotifierItemsProps>;
}

interface NavNotifierItemsProps {
  href?: string | undefined;
  imgSource: string;
  first_name: string;
  last_name: string;
  username: string;
  teamMemberName: string;
  textStuff: string | JSX.Element;
  timeStuff: string;
}

class NavNotifier extends React.Component<NavNotifierProps> {
  render() {
    let noNotifications = this.props.items.length;
    let topBadge = noNotifications ? (
      <span className="badge badge-pill badge-info notifybadge">
        {noNotifications}
      </span>
    ) : (
      <></>
    );

    let notificationsList = this.props.items.map(
      (item: NavNotifierItemsProps, index: number) => {
        let notifyContents = (
          <div className="row">
            <div className="col-md-2">
              <div className="inset">
                <SmartAvatar
                  src={item.imgSource}
                  first_name={item.first_name}
                  last_name={item.last_name}
                  username={item.username}
                />
              </div>
            </div>
            <div className="col-md-10 wrapAfterAll">
              {" "}
              <strong>{item.teamMemberName}</strong> {item.textStuff}
              <br />
              <small className="smallText">{item.timeStuff}</small>
            </div>
          </div>
        );

        if (item.href) {
          return (
            <NavDropdown.Item href={item.href} key={index}>
              {notifyContents}
            </NavDropdown.Item>
          );
        } else {
          return (
            <NavDropdown.Item key={index}>{notifyContents}</NavDropdown.Item>
          );
        }
      }
    );

    return (
      <NavDropdown
        title={
          <>
            <i className="material-icons-outlined notifyicon">notifications</i>
            {topBadge}
          </>
        }
        id="collasible-nav-dropdown"
        className="navDropdownNotify"
      >
        <NavDropdown.Item className="navDropdownNotifyTop">
          <strong>Notifications</strong>{" "}
          <span className="badge badge-pill badge-info">{noNotifications}</span>
        </NavDropdown.Item>
        <NavDropdown.Divider />
        {notificationsList}
        <NavDropdown.Divider />
        <NavDropdown.Item
          href="/notifications"
          className="navDropdownNotifyBottom"
        >
          <strong>See all</strong>
        </NavDropdown.Item>
      </NavDropdown>
    );
  }
}

export default NavNotifier;
