import React from "react";
import {
  store,
  getLoginActions,
  getUserIdActions,
  getJobsActions,
  getAssocUsersActions,
} from "../reduxandotherstuff/arhs";

class LogoutPage extends React.Component {
  componentDidMount() {
    store.dispatch(getLoginActions.getLogout());
    store.dispatch(getUserIdActions.actClear());
    store.dispatch(getJobsActions.actClear());
    store.dispatch(getAssocUsersActions.actClear());
  }

  render() {
    return <div />;
  }
}

export default LogoutPage;
