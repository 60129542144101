import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Table } from "react-bootstrap";
import SmartAvatar from "../components/SmartAvatar";
import { printTeamMemberName } from "../reduxandotherstuff/commonfunc";
import type { TeamMember } from "../reduxandotherstuff/commonfunc";

interface AddJobScheduleProps {
  jobname: string;
  joboptions: Array<Array<string>>;
  starttime: string;
  startdate: string;
  endtime: string;
  enddate: string;
  handleAddJobScheduleModalClose: () => void;
  handleAddJobScheduleModalSubmit: () => void;
  handleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | any
  ) => void;
  handleCheckChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitted: boolean;
  errorMessage: string | JSX.Element;
  addJobScheduleShow: boolean;
  possibleTeamMembers: Array<TeamMember>;
}

function AddJobScheduleModal(props: AddJobScheduleProps) {
  return (
    <Modal
      show={props.addJobScheduleShow}
      onHide={props.handleAddJobScheduleModalClose}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Schedule a Job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={props.handleAddJobScheduleModalSubmit}
          encType="multipart/form-data"
        >
          {props.submitted && props.errorMessage && (
            <div className="alert alert-danger">{props.errorMessage}</div>
          )}
          <div className="form-group row">
            <label className="col-3 col-form-label" htmlFor="jobname">
              Job Name
            </label>
            <div className="col-9">
              <select
                id="jobname"
                name="jobname"
                className="form-control"
                value={props.jobname}
                onChange={props.handleChange}
              >
                {props.joboptions.map((value: Array<string>, index) => {
                  return (
                    <option key={index} value={value[0]}>
                      {value[1]}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {props.submitted && !props.jobname && (
            <div className="alert alert-danger">A job name is required.</div>
          )}
          <div className="form-group row">
            <label className="col-3 col-form-label" htmlFor="startdate">
              Start Date
            </label>
            <div className="col-3">
              <input
                type="date"
                id="startdate"
                name="startdate"
                className="form-control"
                value={props.startdate}
                onChange={props.handleChange}
              />
            </div>
            <label className="col-3 col-form-label" htmlFor="starttime">
              Start Time
            </label>
            <div className="col-3">
              <input
                type="time"
                id="starttime"
                name="starttime"
                className="form-control"
                value={props.starttime}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label" htmlFor="enddate">
              End Date
            </label>
            <div className="col-3">
              <input
                type="date"
                id="enddate"
                name="enddate"
                className="form-control"
                value={props.enddate}
                onChange={props.handleChange}
              />
            </div>
            <label className="col-3 col-form-label" htmlFor="endtime">
              End Time
            </label>
            <div className="col-3">
              <input
                type="time"
                id="endtime"
                name="endtime"
                className="form-control"
                value={props.endtime}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <Table>
            <thead>
              <tr>
                <th>Team Members</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {props.possibleTeamMembers &&
                props.possibleTeamMembers.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <label
                        className="form-check-label"
                        htmlFor={
                          "teammember" +
                          index.toString() +
                          "-" +
                          item.teamMemberId.toString()
                        }
                      >
                        <SmartAvatar
                          src={item.teamMemberAvatarPath}
                          first_name={item.teamMemberFirstName}
                          last_name={item.teamMemberLastName}
                          username={item.teamMemberName}
                        />{" "}
                        {printTeamMemberName(item)}
                      </label>
                    </td>

                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={
                          "teammember" +
                          index.toString() +
                          "-" +
                          item.teamMemberId.toString()
                        }
                        name={
                          "teammember" +
                          index.toString() +
                          "-" +
                          item.teamMemberId.toString()
                        }
                        checked={item.teamMemberChecked}
                        onChange={props.handleCheckChange}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={props.handleAddJobScheduleModalClose}
        >
          Close
        </Button>
        <Button
          variant="outline-success"
          onClick={props.handleAddJobScheduleModalSubmit}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddJobScheduleModal;
export type { TeamMember };
