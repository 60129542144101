import React, { MouseEvent } from "react";
import BootFooter from "../components/BootFooter";
import { Helmet } from "react-helmet";
import type { NavBarHeaderProps } from "../components/NavBarCommon";
import NavBarCommon from "../components/NavBarCommon";
import AddJobScheduleModal from "../components/AddJobScheduleModal";
import SideMenu from "../components/SideMenu";
import type { SideMenuItemProp } from "../components/SideMenu";
import { connect } from "react-redux";
import { store, userService } from "../reduxandotherstuff/arhs";
import type { TeamMember } from "../reduxandotherstuff/commonfunc";
import { history } from "../App";
import { prettifyErrorAlt } from "../reduxandotherstuff/commonfunc";
import TippyContextMenu from "../components/TippyContextMenu";
import YesNoModal from "../components/YesNoModal";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from "react-calendar-timeline";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
const moment = require("moment");

const GROUP_HEIGHT = 40;

interface Props {
  pathname: string;
  getjobsinfo: any;
  getassocusersinfo: any;
  getassoceventssinfo: any;
  match: any;
}

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function getStartDateAndWeekDates(weekstart: string | undefined) {
  let dateOfInterest = !weekstart ? moment() : moment(weekstart, "DD-MM-YYYY");
  if (!dateOfInterest.isValid()) {
    dateOfInterest = moment();
  }
  let startOfDateOfInterest = dateOfInterest.startOf("day");
  let currentDayOfWeek = dateOfInterest.format("dddd");
  let indexinDaysOfWeek = daysOfWeek.indexOf(currentDayOfWeek);
  let startingDate = dateOfInterest.subtract(indexinDaysOfWeek + 1, "days");
  let startingYear = dateOfInterest.year();
  let startingDateFormat = moment(startingDate).format("DD-MM-YYYY");
  let nextWeek = moment(startingDate).add(8, "days").format("DD-MM-YYYY");
  let prevWeek = moment(startingDate).subtract(6, "days").format("DD-MM-YYYY");
  let outputDates = [];
  for (let i = 0; i < 7; i++) {
    outputDates.push(startingDate.add(1, "days").format("dddd, MMMM Do"));
  }
  return [
    startingDateFormat,
    startingYear,
    outputDates,
    prevWeek,
    nextWeek,
    startOfDateOfInterest,
  ];
}

interface State {
  jobname: string;
  joboptions: Array<Array<string>>;
  starttime: string;
  startdate: string;
  endtime: string;
  enddate: string;
  submitted: boolean;
  errorMessage: string | JSX.Element;
  addJobScheduleShow: boolean;
  possibleTeamMembers: Array<TeamMember>;
  schedulestartdate: number;
  scheduleenddate: number;
  deleteEventModalShow: boolean;
  eventtodelete: string;
  jobofeventtodelete: string;
}

const otherItemRenderer = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
}: any) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  let oldItemProps = getItemProps(item.itemProps);
  delete oldItemProps.title;
  let scheduleContextMenu = [
    {
      name: "Delete",
      method: (e: any) => {
        item.removeEvent(
          e.attributes["data-id"].value,
          e.attributes["data-jobid"].value
        );
      },
    },
  ];
  return (
    <TippyContextMenu items={scheduleContextMenu}>
      <div {...oldItemProps} data-id={item.id} data-jobid={item.group}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

        <div
          className="rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
        >
          {itemContext.title}
        </div>
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
      </div>
    </TippyContextMenu>
  );
};

class SchedulePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let basedate = moment().format("YYYY-MM-DD");
    let timeOfInterest = !props.match.params.weekstart
      ? moment()
      : moment(props.match.params.weekstart, "DD-MM-YYYY");

    if (!timeOfInterest.isValid()) {
      timeOfInterest = moment().startOf("day");
    } else {
      timeOfInterest.startOf("day");
    }
    let defaultTimeEnd = moment(timeOfInterest).add(24, "hour");

    this.state = {
      jobname: "",
      joboptions: [],
      starttime: "06:30",
      startdate: basedate,
      endtime: "14:30",
      enddate: basedate,
      submitted: false,
      errorMessage: "",
      addJobScheduleShow: false,
      possibleTeamMembers: [],
      schedulestartdate: timeOfInterest,
      scheduleenddate: defaultTimeEnd,
      deleteEventModalShow: false,
      eventtodelete: "",
      jobofeventtodelete: "",
    };

    this.handleAddJobScheduleModalShow = this.handleAddJobScheduleModalShow.bind(
      this
    );
    this.handleAddJobScheduleModalClose = this.handleAddJobScheduleModalClose.bind(
      this
    );
    this.handleAddJobScheduleModalSubmit = this.handleAddJobScheduleModalSubmit.bind(
      this
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleEmptyTimeClick = this.handleEmptyTimeClick.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleBoundsChange = this.handleBoundsChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.removeEvent = this.removeEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.deleteEventModalClose = this.deleteEventModalClose.bind(this);
  }

  removeEvent(eventid: string, jobid: string) {
    this.setState({ eventtodelete: eventid });
    this.setState({ jobofeventtodelete: jobid });
    this.setState({ deleteEventModalShow: true });
  }

  deleteEventModalClose() {
    this.setState({ deleteEventModalShow: false });
  }

  deleteEvent() {
    this.setState({ deleteEventModalShow: false });
    let localId = localStorage.getItem("userid") || "";
    let p = store.dispatch<any>(
      userService.deleteEvent(
        localId,
        this.state.jobofeventtodelete,
        this.state.eventtodelete
      )
    );
    p.then((value: any) => {
      history.go(0);
    });
  }

  handleTimeChange(
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: (x: number, y: number) => void
  ) {
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
  }

  handleBoundsChange(canvasTimeStart: number, canvasTimeEnd: number) {
    let localId = localStorage.getItem("userid") || "";
    let defaultTimeStart = moment(canvasTimeStart);
    let defaultTimeEnd = moment(canvasTimeEnd);
    store
      .dispatch<any>(
        userService.getAssocEvents(
          localId,
          defaultTimeStart.utc().format(),
          defaultTimeEnd.utc().format()
        )
      )
      .then(window.dispatchEvent(new Event("resize")));
    this.setState({
      schedulestartdate: canvasTimeStart,
      scheduleenddate: canvasTimeEnd,
    });
  }

  handleItemClick(itemId: number, e: MouseEvent<HTMLElement>, time: number) {
    if (e.button === 2) {
      return;
    }
    if (
      this.props.getassoceventssinfo.items &&
      this.props.getassoceventssinfo.items.length > 0
    ) {
      let chosenitems = this.props.getassoceventssinfo.items.filter(
        (x: any) => {
          return x.id === itemId;
        }
      );
      if (chosenitems.length > 0) {
        let jobid = chosenitems[0].job;
        history.push(`/schedule/jobdetail/${jobid}/event/${itemId}`);
        history.go(0);
      }
    }
  }

  handleAddJobScheduleModalShow(e: MouseEvent<HTMLElement>) {
    let tdElem = e.target as Element;
    let tdElemId = tdElem.id;
    let tdElemIdStartCol = tdElemId.indexOf("col");
    let tdElemIdColVal = parseInt(tdElemId.slice(tdElemIdStartCol + 3));
    let tdParentId = tdElem.parentElement!.parentElement!.parentElement!.id;
    let actualDate = moment(tdParentId, "DD-MM-YYYY")
      .add(tdElemIdColVal + 1, "days")
      .format("YYYY-MM-DD");
    let joboptions = [];
    if (this.props.getjobsinfo.items) {
      joboptions = this.props.getjobsinfo.items
        .filter((x: any) => !x.completed && !x.is_removed)
        .sort((a: any, b: any) => {
          return a.id - b.id;
        })
        .map((x: any) => [x.id.toString(), x.jobname]);
    }

    let jobname = joboptions.length > 0 ? joboptions[0][0] : "";
    this.setState({
      addJobScheduleShow: true,
      startdate: actualDate,
      enddate: actualDate,
      jobname: jobname,
      joboptions: joboptions,
    });
  }

  handleEmptyTimeClick(
    groupId: number,
    timeVal: number,
    e: MouseEvent<HTMLElement>
  ) {
    let actualDate = moment(timeVal).local().format("YYYY-MM-DD");
    let joboptions = [];
    if (this.props.getjobsinfo.items) {
      joboptions = this.props.getjobsinfo.items
        .filter((x: any) => !x.completed && !x.is_removed)
        .sort((a: any, b: any) => {
          return a.id - b.id;
        })
        .map((x: any) => [x.id.toString(), x.jobname]);
    }

    let jobname = joboptions.length > 0 ? joboptions[0][0] : "";
    this.setState({
      addJobScheduleShow: true,
      startdate: actualDate,
      enddate: actualDate,
      jobname: jobname,
      joboptions: joboptions,
    });
  }

  handleAddJobScheduleModalClose() {
    this.setState({
      jobname: "",
      joboptions: [],
      starttime: "06:30",
      startdate: moment().format("YYYY-MM-DD"),
      endtime: "14:30",
      enddate: moment().format("YYYY-MM-DD"),
      submitted: false,
      errorMessage: "",
      addJobScheduleShow: false,
    });

    let possibleTeamMembers = [...this.state.possibleTeamMembers];
    for (let i = 0; i < possibleTeamMembers.length; i++) {
      possibleTeamMembers[i].teamMemberChecked = false;
    }
    this.setState({ possibleTeamMembers });
  }

  handleAddJobScheduleModalSubmit() {
    let fieldmatching = {
      job: "Job Name",
      start_time: "Start Date and Start Time",
      end_time: "End Date and End Time",
    };
    let anticiperrors = {};
    this.setState({ submitted: true });
    const {
      jobname,
      startdate,
      enddate,
      starttime,
      endtime,
      possibleTeamMembers,
    } = this.state;
    let start_time_for_server = `${startdate}T${starttime}:00`;
    let end_time_for_server = `${enddate}T${endtime}:00`;
    let teamMembers = possibleTeamMembers
      .filter((x) => x.teamMemberChecked)
      .map((x) => {
        return x.teamMemberId;
      })
      .join(",");
    this.forceUpdate();
    if (!jobname) {
    } else {
      let postdata = {
        job: jobname,
        start_time: moment(start_time_for_server).format(),
        end_time: moment(end_time_for_server).format(),
        shiftworkers: teamMembers,
      };
      let localId = localStorage.getItem("userid") || "";
      let p = store.dispatch<any>(
        userService.postEvent(localId, jobname, postdata)
      );
      p.then((value: any) => {
        if (!value.payload) {
          this.setState({
            addJobScheduleShow: false,
            submitted: false,
            errorMessage: "",
          });
          history.push(`/schedule/jobdetail/${value.job}/event/${value.id}`);
          history.go(0);
        } else {
          this.setState({
            errorMessage: prettifyErrorAlt(
              value.payload.message,
              fieldmatching,
              anticiperrors
            ),
          });
        }
      });
    }
  }

  handleChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) {
    const { name, value } = e.target;
    this.setState<never>({ [name]: value });
  }

  handleCheckChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.target;
    let updateindex = parseInt(name.slice(10, name.indexOf("-")));
    let possibleTeamMembers = [...this.state.possibleTeamMembers];
    let possibleTeamMember = { ...possibleTeamMembers[updateindex] };
    possibleTeamMember.teamMemberChecked = checked;
    possibleTeamMembers[updateindex] = possibleTeamMember;
    this.setState({ possibleTeamMembers });
  }

  componentDidMount() {
    let localId = localStorage.getItem("userid") || "";
    store.dispatch<any>(userService.getAssocUsers(localId)).then(() => {
      if (this.props.getassocusersinfo.items) {
        let assocUserInfo = this.props.getassocusersinfo.items.map((x: any) => {
          return {
            teamMemberName: x.username,
            teamMemberId: x.id,
            teamMemberFirstName: x.first_name,
            teamMemberLastName: x.last_name,
            teamMemberAvatarPath: x.extraprofile.avatar,
            teamMemberChecked: false,
          };
        });
        this.setState({ possibleTeamMembers: assocUserInfo });
      }
    });

    let timeOfInterest = moment(this.state.schedulestartdate);
    let defaultTimeEnd = moment(this.state.scheduleenddate);
    store.dispatch<any>(
      userService.getAssocEvents(
        localId,
        timeOfInterest.utc().format(),
        defaultTimeEnd.utc().format()
      )
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.schedulestartdate !== prevState.schedulestartdate ||
      this.state.scheduleenddate !== prevState.scheduleenddate
    ) {
      window.dispatchEvent(new Event("resize"));
      this.forceUpdate();
    }
  }

  render() {
    let getassoceventssinfo =
      this.props.getassoceventssinfo && this.props.getassoceventssinfo.items
        ? this.props.getassoceventssinfo.items
        : [];
    let getassoceventsjobs = new Set(
      getassoceventssinfo.map((x: any) => x.job)
    );

    let groups =
      this.props.getjobsinfo && this.props.getjobsinfo.items
        ? this.props.getjobsinfo.items
            .map((x: any) => {
              return { id: x.id, title: x.jobname, height: GROUP_HEIGHT };
            })
            .filter((jobitem: any) => getassoceventsjobs.has(jobitem.id))
        : [];

    if (groups.length === 0) {
      groups = [{ id: 0, title: "", height: GROUP_HEIGHT }];
    }

    let timeitems = getassoceventssinfo.map((x: any) => {
      return {
        id: x.id,
        group: x.job,
        title: (
          <>
            <strong>{x.event_jobname}</strong>{" "}
            {moment(x.start_time).local().format("hh:mm a")} -{" "}
            {moment(x.end_time).local().format("hh:mm a")}{" "}
            <i className="material-icons-outlined md-16">group</i>{" "}
            <i>{x.event_shift_count}</i>
          </>
        ),
        start_time: moment(x.start_time),
        end_time: moment(x.end_time),
        itemRenderer: otherItemRenderer,
        removeEvent: this.removeEvent,
        "data-id": x.id,
        "data-jobid": x.job,
        itemProps: {
          // these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
          "data-custom-attribute": "Random content",
          "aria-hidden": true,
          onDoubleClick: () => {
            console.log("You clicked double!");
          },
          className: "kiteflowitem",
          itemRenderer: otherItemRenderer,
          "data-id": x.id,
          "data-jobid": x.job,
        },
      };
    });

    let startingYear = getStartDateAndWeekDates(
      this.props.match.params.weekstart
    )[1];
    let sidemenu: Array<SideMenuItemProp> = [
      {
        icon: "dashboard",
        text: "Job Board",
        enabling: false,
        hrefval: "/jobs",
      },
      {
        icon: "query_builder",
        text: "Schedule",
        enabling: true,
        hrefval: "/schedule",
      },
      {
        icon: "list",
        text: "Newsfeed",
        enabling: false,
        hrefval: "/newsfeed",
      },
      {
        icon: "",
        text: "",
        enabling: false,
        hrefval: "",
      },
      {
        icon: "notifications",
        text: "Notifications",
        enabling: false,
        hrefval: "/notifications",
      },
      {
        icon: "settings",
        text: "Settings",
        enabling: false,
        hrefval: "/settings",
      },
    ];

    const headerItems: Array<NavBarHeaderProps> = [
      {
        isActive: false,
        href: "/",
        headerTitle: "Home",
        headerId: "home",
        dropdownitems: [],
      },
      {
        isActive: false,
        href: "/jobs",
        headerTitle: "Job Board",
        headerId: "jobboard",
        dropdownitems: [],
      },
      {
        isActive: false,
        href: "",
        headerTitle: "View",
        headerId: "viewevents",
        dropdownitems: [
          {
            navText: "View Event Calendar",
            href: `/schedule/calendar/${startingYear}`,
          },
        ],
      },
    ];
    let defA = moment(this.state.schedulestartdate);
    let defB = moment(this.state.scheduleenddate);

    const formatTrial = {
      year: {
        long: "YYYY",
        mediumLong: "YYYY",
        medium: "YYYY",
        short: "YY",
      },
      month: {
        long: "MMMM YYYY",
        mediumLong: "MMMM",
        medium: "MMMM",
        short: "MM/YY",
      },
      week: {
        long: "w",
        mediumLong: "w",
        medium: "w",
        short: "w",
      },
      day: {
        long: "dddd, LL",
        mediumLong: "dddd, LL",
        medium: "dd D",
        short: "D",
      },
      hour: {
        long: "dddd, LL, ha",
        mediumLong: "L, ha",
        medium: "ha",
        short: "ha",
      },
      minute: {
        long: "h:mma",
        mediumLong: "h:mma",
        medium: "h:mm",
        short: "mm",
      },
    };

    function formatLabelAlt(
      [timeStart, timeEnd]: [any, any],
      unit: string,
      labelWidth: number
    ) {
      let format;
      if (labelWidth >= 150) {
        format = (formatTrial as any)[unit]["long"];
      } else if (labelWidth >= 100) {
        format = (formatTrial as any)[unit]["mediumLong"];
      } else if (labelWidth >= 50) {
        format = (formatTrial as any)[unit]["medium"];
      } else {
        format = (formatTrial as any)[unit]["short"];
      }
      return timeStart.format(format);
    }

    return (
      <div>
        <Helmet>
          <title>Schedge | Schedule</title>
        </Helmet>
        <NavBarCommon headerItems={headerItems} />
        <main className="container-fluid" role="main">
          <div className="row">
            <div className="col-md-2">
              <p>&nbsp;</p>
              <SideMenu entries={sidemenu} />
            </div>
            <div className="col-md-10">
              <h1>Schedule</h1>

              <Timeline
                groups={groups}
                items={timeitems}
                defaultTimeStart={defA}
                defaultTimeEnd={defB}
                onCanvasClick={this.handleEmptyTimeClick}
                onItemSelect={this.handleItemClick}
                onBoundsChange={this.handleBoundsChange}
                onTimeChange={this.handleTimeChange}
                canMove={false}
                canChangeGroup={false}
                canResize={false}
                itemRenderer={otherItemRenderer}
                stackItems
              >
                <TimelineHeaders>
                  <SidebarHeader />
                  <DateHeader
                    unit="primaryHeader"
                    labelFormat={formatLabelAlt}
                  />
                  <DateHeader labelFormat={formatLabelAlt} />
                </TimelineHeaders>
              </Timeline>
            </div>
          </div>
        </main>
        <BootFooter />
        <AddJobScheduleModal
          jobname={this.state.jobname}
          joboptions={this.state.joboptions}
          starttime={this.state.starttime}
          startdate={this.state.startdate}
          endtime={this.state.endtime}
          enddate={this.state.enddate}
          handleAddJobScheduleModalClose={this.handleAddJobScheduleModalClose}
          handleAddJobScheduleModalSubmit={this.handleAddJobScheduleModalSubmit}
          handleChange={this.handleChange}
          submitted={this.state.submitted}
          errorMessage={this.state.errorMessage}
          addJobScheduleShow={this.state.addJobScheduleShow}
          possibleTeamMembers={this.state.possibleTeamMembers}
          handleCheckChange={this.handleCheckChange}
        />
        <YesNoModal
          yesnoTitle="Delete"
          yesnoQuestion="Do you wish to delete this event?"
          yesnoModalShow={this.state.deleteEventModalShow}
          YesNoModalSubmit={this.deleteEvent}
          YesNoModalClose={this.deleteEventModalClose}
        />
      </div>
    );
  }
}

function mapStateToProps(state: Props) {
  return {
    getjobsinfo: state.getjobsinfo,
    getassocusersinfo: state.getassocusersinfo,
    getassoceventssinfo: state.getassoceventssinfo,
  };
}

export default connect(mapStateToProps)(SchedulePage);
