import React, { useRef, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
//import SearchComponent from "../components/SearchComponent";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { store, userService } from "../reduxandotherstuff/arhs";
import { history } from "../App";
import { prettifyErrorAlt } from "../reduxandotherstuff/commonfunc";
import type { GeoPosition } from "../reduxandotherstuff/commonfunc";
import * as constants from "../reduxandotherstuff/constants";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import DropZoneComponent from "../components/DropZoneComponent";
const moment = require("moment");

// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
  iconUrl: require("leaflet/dist/images/marker-icon.png").default,
  shadowUrl: require("leaflet/dist/images/marker-shadow.png").default,
});

interface AddJobModalCallerProps {}

interface AddJobModalCallerState {
  jobname: string;
  client: string;
  description: string;
  submitted: boolean;
  errorMessage: string | JSX.Element;
  addJobModalShow: boolean;
  startdate: string;
  enddate: string;
  autosign_workers: boolean;
  dist_autosign: number;
  job_autosign: boolean;
  center: GeoPosition;
  marker: GeoPosition;
  zoom: number;
  draggable: boolean;
  autogen_inspections: boolean;
  inspection_day_of_month: number;
}

interface AddJobModalProps extends AddJobModalCallerState {
  handleAddJobModalClose: () => void;
  handleAddJobModalSubmit: () => void;
  handleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleAutosignChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleJobAutosignChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAutoGenInspectChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  refmarker: React.RefObject<any>;
  toggleDraggable: () => void;
  updatePosition: (refmarker: React.RefObject<any>) => void;
  setMarker: (position: GeoPosition) => void;
  draganddropFileInput: React.RefObject<HTMLInputElement>;
}

function handleAddJobModalShow(
  that: React.Component<AddJobModalCallerProps, AddJobModalCallerState>
) {
  that.setState({ addJobModalShow: true });
}

function handleAddJobModalClose(
  that: React.Component<AddJobModalCallerProps, AddJobModalCallerState>,
  draganddropFileInput: React.RefObject<HTMLInputElement>
) {
  that.setState({
    jobname: "",
    client: "",
    description: "",
    submitted: false,
    errorMessage: "",
    addJobModalShow: false,
    autosign_workers: false,
    dist_autosign: 200,
    zoom: 13,
    draggable: true,
    center: {
      lat: constants.BRISBANE_LATITUDE,
      lng: constants.BRISBANE_LONGITUDE,
    },
    marker: {
      lat: constants.BRISBANE_LATITUDE,
      lng: constants.BRISBANE_LONGITUDE,
    },
    job_autosign: false,
    inspection_day_of_month: 7,
    autogen_inspections: false,
    startdate: moment().format("YYYY-MM-DD"),
    enddate: moment().format("YYYY-MM-DD"),
  });
  draganddropFileInput!.current!.files = new DataTransfer().files;
}

function handleAddJobModalSubmit(
  that: React.Component<AddJobModalCallerProps, AddJobModalCallerState>,
  draganddropFileInput: React.RefObject<HTMLInputElement>
) {
  let fieldmatching = {
    jobname: "Job Name",
  };
  let anticiperrors = {};
  that.setState({ submitted: true });
  const {
    jobname,
    client,
    description,
    marker,
    dist_autosign,
    autosign_workers,
    job_autosign,
    inspection_day_of_month,
    autogen_inspections,
    startdate,
    enddate,
  } = that.state;
  that.forceUpdate();
  if (!jobname) {
  } else {
    let inspections = "";
    let momentstartdate = moment(startdate);
    let startdatetime = moment(startdate).hour(23).minute(59).second(59);
    let enddatetime = moment(enddate).hour(23).minute(59).second(59);
    if (autogen_inspections && momentstartdate.isSameOrBefore(enddatetime)) {
      let autogeninspectionarray = [];

      if (momentstartdate.date() < inspection_day_of_month) {
        let actualday = Math.min(
          inspection_day_of_month,
          momentstartdate.daysInMonth()
        );
        autogeninspectionarray.push(
          momentstartdate
            .date(actualday)
            .hour(23)
            .minute(59)
            .second(59)
            .format()
        );
      }

      // Get first day of next month

      momentstartdate.date(1).add(1, "months");
      let actualday = Math.min(
        inspection_day_of_month,
        momentstartdate.daysInMonth()
      );
      momentstartdate.date(actualday);
      while (momentstartdate.isSameOrBefore(enddatetime)) {
        autogeninspectionarray.push(
          momentstartdate.hour(23).minute(59).second(59).format()
        );
        momentstartdate.date(1).add(1, "months");
        let actualday = Math.min(
          inspection_day_of_month,
          momentstartdate.daysInMonth()
        );
        momentstartdate.date(actualday);
      }
      inspections = autogeninspectionarray.join(",");
    }
    let anticipated_start_time = startdatetime.isValid()
      ? startdatetime.format()
      : null;
    let anticipated_end_time = enddatetime.isValid()
      ? enddatetime.format()
      : null;
    let postdata = {
      jobname: jobname,
      description: description,
      client: client,
      dist_autosign: dist_autosign,
      autosign_workers: job_autosign ? autosign_workers : null,
      job_location: `SRID=4326;POINT (${marker.lng} ${marker.lat})`,
      inspections: inspections,
      anticipated_start_time: anticipated_start_time,
      anticipated_end_time: anticipated_end_time,
      "job_files[]": draganddropFileInput!.current!.files,
    };
    let localId = localStorage.getItem("userid") || "";
    let p = store.dispatch<any>(userService.postJob(localId, postdata));
    p.then((value: any) => {
      if (!value.payload) {
        that.setState({
          addJobModalShow: false,
          submitted: false,
          errorMessage: "",
        });
        draganddropFileInput!.current!.files = new DataTransfer().files;
        history.push(`/jobs/${value.id}`);
        history.go(0);
      } else {
        that.setState({
          errorMessage: prettifyErrorAlt(
            value.payload.message,
            fieldmatching,
            anticiperrors
          ),
        });
      }
    });
  }
}

// Adapted from https://codesandbox.io/s/removable-drop-zone-82km9

function AddJobModal(props: AddJobModalProps) {
  const mapRef = useRef<any>();
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      if (mapRef.current) {
        mapRef.current.leafletElement.invalidateSize(false);
      }
    } else didMountRef.current = true;
  });
  let jobMap = <></>;

  jobMap = (
    <Map
      ref={mapRef}
      center={[props.center.lat, props.center.lng]}
      zoom={props.zoom}
      scrollWheelZoom={true}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker
        position={[props.marker.lat, props.marker.lng]}
        ref={props.refmarker}
        draggable={props.draggable}
        onDragend={props.updatePosition}
      >
        <Popup minWidth={90}>
          <span onClick={props.toggleDraggable}>
            {props.draggable
              ? "The marker is draggable. Click here to fix."
              : "The marker is fixed. Click here to make it draggable."}
          </span>
        </Popup>
{/*        <SearchComponent setMarker={props.setMarker} /> */}
      </Marker>
    </Map>
  );

  return (
    <Modal
      show={props.addJobModalShow}
      onHide={props.handleAddJobModalClose}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={props.handleAddJobModalSubmit}
          encType="multipart/form-data"
        >
          {props.submitted && props.errorMessage && (
            <div className="alert alert-danger">{props.errorMessage}</div>
          )}
          <div className="form-group row">
            <label className="col-3 col-form-label" htmlFor="jobname">
              Job Name
            </label>
            <div className="col-9">
              <input
                type="text"
                id="jobname"
                name="jobname"
                className="form-control"
                value={props.jobname}
                onChange={props.handleChange}
              />
            </div>
          </div>
          {props.submitted && !props.jobname && (
            <div className="alert alert-danger">A job name is required.</div>
          )}
          <div className="form-group row">
            <label className="col-3 col-form-label" htmlFor="client">
              Client Name
            </label>
            <div className="col-9">
              <input
                type="text"
                id="client"
                name="client"
                className="form-control"
                value={props.client}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label" htmlFor="description">
              Description
            </label>
            <div className="col-9">
              <textarea
                className="form-control"
                id="description"
                name="description"
                rows={3}
                onChange={props.handleChange}
                value={props.description}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label" htmlFor="startdate">
              Anticipated Start Date
            </label>
            <div className="col-3">
              <input
                type="date"
                id="startdate"
                name="startdate"
                className="form-control"
                value={props.startdate}
                onChange={props.handleChange}
              />
            </div>
            <label className="col-3 col-form-label" htmlFor="enddate">
              Anticipated End Date
            </label>
            <div className="col-3">
              <input
                type="date"
                id="enddate"
                name="enddate"
                className="form-control"
                value={props.enddate}
                onChange={props.handleChange}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-6">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="autogen_inspections"
                  name="autogen_inspections"
                  checked={props.autogen_inspections}
                  onChange={props.handleAutoGenInspectChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="autogen_inspections"
                >
                  Autogenerate inspection dates?
                  <br />
                  (They can be changed later.)
                </label>
              </div>
            </div>
            <label
              className="col-3 col-form-label"
              htmlFor="inspection_day_of_month"
            >
              Monthly inspection day of month
            </label>
            <div className="col-3">
              <input
                className="form-control"
                type="number"
                name="inspection_day_of_month"
                id="inspection_day_of_month"
                value={props.inspection_day_of_month}
                min="1"
                max="31"
                step="1"
                onChange={props.handleChange}
                disabled={!props.autogen_inspections}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="job_autosign"
                  name="job_autosign"
                  checked={props.job_autosign}
                  onChange={props.handleJobAutosignChange}
                />
                <label className="form-check-label" htmlFor="job_autosign">
                  Choose job specific autosign settings?
                </label>
              </div>
            </div>
            <div className="col-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="autosign_workers"
                  name="autosign_workers"
                  checked={props.autosign_workers}
                  onChange={props.handleAutosignChange}
                  disabled={!props.job_autosign}
                />
                <label className="form-check-label" htmlFor="autosign_workers">
                  Autosign workers?
                </label>
              </div>
            </div>

            <label className="col-3 col-form-label" htmlFor="dist_autosign">
              Autosign Distance (m)
            </label>
            <div className="col-3">
              <input
                className="form-control"
                type="number"
                name="dist_autosign"
                id="dist_autosign"
                value={props.dist_autosign}
                min="0"
                step="1"
                onChange={props.handleChange}
                disabled={!props.job_autosign}
              />
            </div>
          </div>

          <div className="form-group row mapSelector">
            <label className="col-12 col-form-label">
              Job Location. (Drag marker on the map, or search by address to
              find the location of the job.)
            </label>

            {jobMap}
          </div>
          <div className="form-group row mapSelector">
            <label className="col-12 col-form-label">Job files.</label>
            <DropZoneComponent
              setref={props.draganddropFileInput}
              multiple={true}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={props.handleAddJobModalClose}
        >
          Close
        </Button>
        <Button
          variant="outline-success"
          onClick={props.handleAddJobModalSubmit}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddJobModal;

export {
  handleAddJobModalShow,
  handleAddJobModalClose,
  handleAddJobModalSubmit,
};

export type { AddJobModalCallerState };
