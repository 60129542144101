import React, { useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
var path = require("path");

/* 
// This is a drag and drop components integrating ideas from the following
// sources:
//
// 1.How to actually accept a file (and react to it):
// https://codesandbox.io/s/removable-drop-zone-82km9
//
// 2. How to colour it depending on whether files are accepted or rejected:
// https://react-dropzone.js.org/ "Using inline styles"
//
// 3. Getting a ref in there that can be used outside.
// https://github.com/react-dropzone/react-dropzone/issues/838
*/

interface Props {
  setref: any;
  multiple: boolean;
  existfile?: string;
}

function DropZoneComponent(props: Props) {
  const [myFiles, setMyFiles] = React.useState<any>([]);

  const onDrop = React.useCallback(
    (accepted: any, rejected: any, event: any) => {
      if (props.multiple) {
        setMyFiles([...myFiles, ...accepted]);
      } else {
        setMyFiles([...accepted]);
      }
      if (event.type === "drop") {
        const dt = new DataTransfer();
        const existfiles = Array.from(inputRef!.current!.files!);
        if (props.multiple) {
          for (let existfile of existfiles) {
            dt.items.add(existfile);
          }
        }
        for (let file of accepted) {
          dt.items.add(file); // Add only file name not matched files
        }
        inputRef!.current!.files! = dt.files; // Overwrite files
      } else {
        const dt = new DataTransfer();
        if (props.multiple) {
          for (let existfile of myFiles) {
            dt.items.add(existfile); // Add only file name not matched files
          }
        }
        for (let existfile of accepted) {
          dt.items.add(existfile); // Add only file name not matched files
        }
        inputRef!.current!.files! = dt.files; // Overwrite files
      }
    },
    [myFiles, props.multiple]
  );

  const {
    getRootProps,
    getInputProps,
    inputRef,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noKeyboard: true,
    maxSize: 20971520,
    multiple: props.multiple,
    maxFiles: props.multiple ? 0 : 1,
    accept: "image/*,.pdf",
    onDrop,
  });

  const handleRemoveFile = React.useCallback(
    (index) => {
      const dt = new DataTransfer();
      let files = Array.from(inputRef!.current!.files!);
      files.splice(index, 1);
      for (let file of files) {
        dt.items.add(file);
      }
      inputRef!.current!.files! = dt.files; // Overwrite files
      setMyFiles(Array.from(dt.files)); // Set states to render file list
    },
    [inputRef]
  );

  const files = React.useMemo<any>(
    () =>
      myFiles.map((file: any, index: number) => (
        <tr>
          {" "}
          <th key={file.name}>{file.name}</th>
          <td>{file.size}</td>
          <td>
            <Button
              variant="outline-success"
              onClick={() => handleRemoveFile(index)}
            >
              Remove file
            </Button>
          </td>{" "}
        </tr>
      )),
    [handleRemoveFile, myFiles]
  );

  const baseStyle = {
    flex: 1,
    display: "flex",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [
      isDragActive,
      isDragReject,
      isDragAccept,
      acceptStyle,
      activeStyle,
      baseStyle,
      rejectStyle,
    ]
  );

  useEffect(() => {
    if (inputRef.current !== props.setref.current) {
      props.setref.current = inputRef.current;
    }
  }, [props.setref, inputRef, myFiles]);
  const dropText = !props.multiple
    ? "Drag and drop a file here, or click to select a file."
    : "Drag and drop some files here, or click to select files.";

  let labelText = props.existfile ? (
    <p>
      {dropText}
      <br />
      Existing file: {path.basename(props.existfile)}
    </p>
  ) : (
    <p>{dropText}</p>
  );

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone", style: style })}>
        <input {...getInputProps()} />
        {labelText}
      </div>
      {files.length > 0 ? (
        <div>
          <table className="table table-hover plan-table">
            <thead className="plan-table-thead">
              <tr className="plan-table-tr">
                <th className="plan-table-th col">File name</th>
                <th className="plan-table-th col">Length (bytes)</th>
                <th className="plan-table-th col"></th>
              </tr>
            </thead>
            <tbody className="plan-table-tbody">{files}</tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </section>
  );
}

export default DropZoneComponent;
