// arh.js - short for Actions, Reducers and HTTP requests.
import thunkMiddleware from "redux-thunk";
import {
  createStore,
  combineReducers,
  AnyAction,
  applyMiddleware,
  compose,
} from "redux";
import { externalDomain } from "./env";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { history } from "../App";
import reduxWebsocket, {
  connect,
  disconnect,
} from "@giantmachines/redux-websocket";
const { serializeError } = require("serialize-error");

const domainStart = externalDomain;
//const webSocketInt =
//  externalDomain === "http://127.0.0.1:8000"
//    ? `ws://127.0.0.1:8000/ws/scaf/`
//    : `wss://www.scafflow.com/ws/scaf/`;
const reduxWebsocketMiddleware = reduxWebsocket();

// Let's gets some enum constants happening. They help us debug any Redux errors.

// First, the "type" of actions we need.

enum REDUX_TYPE_ENUMS {
  DUMMY_INFO,
  GET_LOGIN_BEGIN,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAILURE,
  CLEAR_LOGIN,
  GET_USERID_BEGIN,
  GET_USERID_SUCCESS,
  GET_USERID_FAILURE,
  CLEAR_USERID,
  GET_USER_BEGIN,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_ASSOCUSERS_BEGIN,
  GET_ASSOCUSERS_SUCCESS,
  GET_ASSOCUSERS_FAILURE,
  CLEAR_ASSOCUSERS,
  GET_UNREADMSGS_BEGIN,
  GET_UNREADMSGS_SUCCESS,
  GET_UNREADMSGS_FAILURE,
  CLEAR_UNREADMSGS,
  PUT_UNREADMSG_BEGIN,
  PUT_UNREADMSG_SUCCESS,
  PUT_UNREADMSG_FAILURE,
  GET_UNCONFIRMEDS_BEGIN,
  GET_UNCONFIRMEDS_SUCCESS,
  GET_UNCONFIRMEDS_FAILURE,
  CLEAR_UNCONFIRMEDS,
  PUT_UNCONFIRMED_BEGIN,
  PUT_UNCONFIRMED_SUCCESS,
  PUT_UNCONFIRMED_FAILURE,
  GET_CONFIRMUNKNOWNS_BEGIN,
  GET_CONFIRMUNKNOWNS_SUCCESS,
  GET_CONFIRMUNKNOWNS_FAILURE,
  CLEAR_CONFIRMUNKNOWNS,
  PUT_CONFIRMUNKNOWN_BEGIN,
  PUT_CONFIRMUNKNOWN_SUCCESS,
  PUT_CONFIRMUNKNOWN_FAILURE,
  GET_ASSOCEVENTS_BEGIN,
  GET_ASSOCEVENTS_SUCCESS,
  GET_ASSOCEVENTS_FAILURE,
  CLEAR_ASSOCEVENTS,
  POST_PASSWORD_BEGIN,
  POST_PASSWORD_SUCCESS,
  POST_PASSWORD_FAILURE,
  PUT_USER_BEGIN,
  PUT_USER_SUCCESS,
  PUT_USER_FAILURE,
  GET_JOBS_BEGIN,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAILURE,
  CLEAR_JOBS,
  GET_JOB_BEGIN,
  GET_JOB_SUCCESS,
  GET_JOB_FAILURE,
  POST_JOB_BEGIN,
  POST_JOB_SUCCESS,
  POST_JOB_FAILURE,
  PUT_JOB_BEGIN,
  PUT_JOB_SUCCESS,
  PUT_JOB_FAILURE,
  DELETE_JOB_BEGIN,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,
  GET_LEVELS_BEGIN,
  GET_LEVELS_SUCCESS,
  GET_LEVELS_FAILURE,
  CLEAR_LEVELS,
  POST_LEVEL_BEGIN,
  POST_LEVEL_SUCCESS,
  POST_LEVEL_FAILURE,
  PUT_LEVEL_BEGIN,
  PUT_LEVEL_SUCCESS,
  PUT_LEVEL_FAILURE,
  DELETE_LEVEL_BEGIN,
  DELETE_LEVEL_SUCCESS,
  DELETE_LEVEL_FAILURE,
  GET_VARIATIONS_BEGIN,
  GET_VARIATIONS_SUCCESS,
  GET_VARIATIONS_FAILURE,
  POST_VARIATION_BEGIN,
  POST_VARIATION_SUCCESS,
  POST_VARIATION_FAILURE,
  PUT_VARIATION_BEGIN,
  PUT_VARIATION_SUCCESS,
  PUT_VARIATION_FAILURE,
  CLEAR_VARIATIONS,
  DELETE_VARIATION_BEGIN,
  DELETE_VARIATION_SUCCESS,
  DELETE_VARIATION_FAILURE,
  GET_SWMSS_BEGIN,
  GET_SWMSS_SUCCESS,
  GET_SWMSS_FAILURE,
  CLEAR_SWMSS,
  POST_SWMS_BEGIN,
  POST_SWMS_SUCCESS,
  POST_SWMS_FAILURE,
  PUT_SWMS_BEGIN,
  PUT_SWMS_SUCCESS,
  PUT_SWMS_FAILURE,
  DELETE_SWMS_BEGIN,
  DELETE_SWMS_SUCCESS,
  DELETE_SWMS_FAILURE,
  GET_EVENTS_BEGIN,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  CLEAR_EVENTS,
  GET_EVENT_BEGIN,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  POST_EVENT_BEGIN,
  POST_EVENT_SUCCESS,
  POST_EVENT_FAILURE,
  PUT_EVENT_BEGIN,
  PUT_EVENT_SUCCESS,
  PUT_EVENT_FAILURE,
  DELETE_EVENT_BEGIN,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  GET_SHIFTS_BEGIN,
  GET_SHIFTS_SUCCESS,
  GET_SHIFTS_FAILURE,
  CLEAR_SHIFTS,
  DELETE_SHIFT_BEGIN,
  DELETE_SHIFT_SUCCESS,
  DELETE_SHIFT_FAILURE,
  GET_CHANNELS_BEGIN,
  GET_CHANNELS_SUCCESS,
  GET_CHANNELS_FAILURE,
  CLEAR_CHANNELS,
  GET_CHANNEL_BEGIN,
  GET_CHANNEL_SUCCESS,
  GET_CHANNEL_FAILURE,
  POST_CHANNEL_BEGIN,
  POST_CHANNEL_SUCCESS,
  POST_CHANNEL_FAILURE,
  PUT_CHANNEL_BEGIN,
  PUT_CHANNEL_SUCCESS,
  PUT_CHANNEL_FAILURE,
  DELETE_CHANNEL_BEGIN,
  DELETE_CHANNEL_SUCCESS,
  DELETE_CHANNEL_FAILURE,
  GET_MESSAGES_BEGIN,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,
  CLEAR_MESSAGES,
  GET_MESSAGE_BEGIN,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_FAILURE,
  POST_MESSAGE_BEGIN,
  POST_MESSAGE_SUCCESS,
  POST_MESSAGE_FAILURE,
  PUT_MESSAGE_BEGIN,
  PUT_MESSAGE_SUCCESS,
  PUT_MESSAGE_FAILURE,
  DELETE_MESSAGE_BEGIN,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
  GET_DMSS_BEGIN,
  GET_DMSS_SUCCESS,
  GET_DMSS_FAILURE,
  CLEAR_DMSS,
  GET_DMS_BEGIN,
  GET_DMS_SUCCESS,
  GET_DMS_FAILURE,
  POST_DMS_BEGIN,
  POST_DMS_SUCCESS,
  POST_DMS_FAILURE,
  PUT_DMS_BEGIN,
  PUT_DMS_SUCCESS,
  PUT_DMS_FAILURE,
  DELETE_DMS_BEGIN,
  DELETE_DMS_SUCCESS,
  DELETE_DMS_FAILURE,
}

/* Then the type of methods we may deal with. */

enum HTTP_METHODS {
  GET,
  POST,
  PUT,
  DELETE,
}

function typify(x: REDUX_TYPE_ENUMS) {
  return REDUX_TYPE_ENUMS[x];
}

function methodify(x: HTTP_METHODS) {
  return HTTP_METHODS[x];
}

/* There are common patterns reducers appear to satisfy, so let's give some
constants for initialisation. */

/* For getting individual items. */

const initialGETState = {
  item: null,
  loading: false,
  error: null,
};

/* For listing items and posting. */

const initialLISTandPOSTState = {
  items: null,
  loading: false,
  error: null,
};

/* For putting and deleting. */

const initialPUTandDELETEState = {
  item: [],
  loading: false,
  error: null,
};

/* For reducers for listing items. */

function defaultLISTReducer(
  state: any,
  action: AnyAction,
  actBeginType: REDUX_TYPE_ENUMS,
  actSuccessType: REDUX_TYPE_ENUMS,
  actFailureType: REDUX_TYPE_ENUMS,
  actClearType: REDUX_TYPE_ENUMS
) {
  switch (action.type) {
    case typify(actBeginType):
      return {
        items: [],
        loading: true,
        error: null,
      };

    case typify(actSuccessType):
      return {
        items: action.payload,
        loading: false,
        error: null,
      };

    case typify(actFailureType):
      return {
        items: [],
        loading: false,
        error: action.payload,
      };

    case typify(actClearType):
      return {
        items: null,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
}

/* For reducers for getting items. */

function defaultGETReducer(
  state: any,
  action: AnyAction,
  actBeginType: REDUX_TYPE_ENUMS,
  actSuccessType: REDUX_TYPE_ENUMS,
  actFailureType: REDUX_TYPE_ENUMS
) {
  switch (action.type) {
    case typify(actBeginType):
      return {
        item: null,
        loading: true,
        error: null,
      };

    case typify(actSuccessType):
      return {
        item: action.payload,
        loading: false,
        error: null,
      };

    case typify(actFailureType):
      return {
        item: null,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

/* For reducers for posting items. */

function defaultPOSTReducer(
  state: any,
  action: AnyAction,
  actBeginType: REDUX_TYPE_ENUMS,
  actSuccessType: REDUX_TYPE_ENUMS,
  actFailureType: REDUX_TYPE_ENUMS
) {
  switch (action.type) {
    case typify(actBeginType):
      return {
        items: [],
        loading: true,
        error: null,
      };

    case typify(actSuccessType):
      return {
        items: action.payload,
        loading: false,
        error: null,
      };

    case typify(actFailureType):
      return {
        items: [],
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

/* For reducers for putting items. */

function defaultPUTReducer(
  state: any,
  action: AnyAction,
  actBeginType: REDUX_TYPE_ENUMS,
  actSuccessType: REDUX_TYPE_ENUMS,
  actFailureType: REDUX_TYPE_ENUMS
) {
  switch (action.type) {
    case typify(actBeginType):
      return {
        item: [],
        loading: true,
        error: null,
      };

    case typify(actSuccessType):
      return {
        item: action.payload,
        loading: false,
        error: null,
      };

    case typify(actFailureType):
      return {
        item: [],
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

/* For reducers for deleting items. */

function defaultDELETEReducer(
  state: any,
  action: AnyAction,
  actBeginType: REDUX_TYPE_ENUMS,
  actSuccessType: REDUX_TYPE_ENUMS,
  actFailureType: REDUX_TYPE_ENUMS
) {
  switch (action.type) {
    case typify(actBeginType):
      return {
        item: [],
        loading: true,
        error: null,
      };

    case typify(actSuccessType):
      return {
        item: action.payload,
        loading: false,
        error: null,
      };

    case typify(actFailureType):
      return {
        item: [],
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

export function dummyinfo(state = {}, action: AnyAction) {
  switch (action.type) {
    case typify(REDUX_TYPE_ENUMS.DUMMY_INFO):
      return {};
    default:
      return state;
  }
}

export function getlogininfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_LOGIN_BEGIN,
    REDUX_TYPE_ENUMS.GET_LOGIN_SUCCESS,
    REDUX_TYPE_ENUMS.GET_LOGIN_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_LOGIN
  );
}

export function getuseridinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_USERID_BEGIN,
    REDUX_TYPE_ENUMS.GET_USERID_SUCCESS,
    REDUX_TYPE_ENUMS.GET_USERID_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_USERID
  );
}

export function getassocusersinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_ASSOCUSERS_BEGIN,
    REDUX_TYPE_ENUMS.GET_ASSOCUSERS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_ASSOCUSERS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_ASSOCUSERS
  );
}

export function getunreadmsgsinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_UNREADMSGS_BEGIN,
    REDUX_TYPE_ENUMS.GET_UNREADMSGS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_UNREADMSGS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_UNREADMSGS
  );
}

export function putunreadmsginfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultPUTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.PUT_UNREADMSG_BEGIN,
    REDUX_TYPE_ENUMS.PUT_UNREADMSG_SUCCESS,
    REDUX_TYPE_ENUMS.PUT_UNREADMSG_FAILURE
  );
}

export function getunconfirmedsinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_UNCONFIRMEDS_BEGIN,
    REDUX_TYPE_ENUMS.GET_UNCONFIRMEDS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_UNCONFIRMEDS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_UNCONFIRMEDS
  );
}

export function putunconfirmedinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultPUTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.PUT_UNCONFIRMED_BEGIN,
    REDUX_TYPE_ENUMS.PUT_UNCONFIRMED_SUCCESS,
    REDUX_TYPE_ENUMS.PUT_UNCONFIRMED_FAILURE
  );
}

export function getconfirmunknownsinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_CONFIRMUNKNOWNS_BEGIN,
    REDUX_TYPE_ENUMS.GET_CONFIRMUNKNOWNS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_CONFIRMUNKNOWNS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_CONFIRMUNKNOWNS
  );
}

export function putconfirmunknowninfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultPUTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.PUT_CONFIRMUNKNOWN_BEGIN,
    REDUX_TYPE_ENUMS.PUT_CONFIRMUNKNOWN_SUCCESS,
    REDUX_TYPE_ENUMS.PUT_CONFIRMUNKNOWN_FAILURE
  );
}

export function getassoceventssinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_ASSOCEVENTS_BEGIN,
    REDUX_TYPE_ENUMS.GET_ASSOCEVENTS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_ASSOCEVENTS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_ASSOCEVENTS
  );
}

export function getuserinfo(state = initialGETState, action: AnyAction) {
  return defaultGETReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_USER_BEGIN,
    REDUX_TYPE_ENUMS.GET_USER_SUCCESS,
    REDUX_TYPE_ENUMS.GET_USER_FAILURE
  );
}

export function postpasswordinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultPOSTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.POST_PASSWORD_BEGIN,
    REDUX_TYPE_ENUMS.POST_PASSWORD_SUCCESS,
    REDUX_TYPE_ENUMS.POST_PASSWORD_FAILURE
  );
}

export function putuserinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultPUTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.PUT_USER_BEGIN,
    REDUX_TYPE_ENUMS.PUT_USER_SUCCESS,
    REDUX_TYPE_ENUMS.PUT_USER_FAILURE
  );
}

export function getjobsinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_JOBS_BEGIN,
    REDUX_TYPE_ENUMS.GET_JOBS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_JOBS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_JOBS
  );
}

export function getjobinfo(state = initialGETState, action: AnyAction) {
  return defaultGETReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_JOB_BEGIN,
    REDUX_TYPE_ENUMS.GET_JOB_SUCCESS,
    REDUX_TYPE_ENUMS.GET_JOB_FAILURE
  );
}

export function postjobinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultPOSTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.POST_JOB_BEGIN,
    REDUX_TYPE_ENUMS.POST_JOB_SUCCESS,
    REDUX_TYPE_ENUMS.POST_JOB_FAILURE
  );
}

export function putjobinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultPUTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.PUT_JOB_BEGIN,
    REDUX_TYPE_ENUMS.PUT_JOB_SUCCESS,
    REDUX_TYPE_ENUMS.PUT_JOB_FAILURE
  );
}

export function deletejobinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultDELETEReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.DELETE_JOB_BEGIN,
    REDUX_TYPE_ENUMS.DELETE_JOB_SUCCESS,
    REDUX_TYPE_ENUMS.DELETE_JOB_FAILURE
  );
}

export function getlevelsinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_LEVELS_BEGIN,
    REDUX_TYPE_ENUMS.GET_LEVELS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_LEVELS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_LEVELS
  );
}

export function postlevelinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultPOSTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.POST_LEVEL_BEGIN,
    REDUX_TYPE_ENUMS.POST_LEVEL_SUCCESS,
    REDUX_TYPE_ENUMS.POST_LEVEL_FAILURE
  );
}

export function putlevelinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultPUTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.PUT_LEVEL_BEGIN,
    REDUX_TYPE_ENUMS.PUT_LEVEL_SUCCESS,
    REDUX_TYPE_ENUMS.PUT_LEVEL_FAILURE
  );
}

export function deletelevelinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultDELETEReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.DELETE_LEVEL_BEGIN,
    REDUX_TYPE_ENUMS.DELETE_LEVEL_SUCCESS,
    REDUX_TYPE_ENUMS.DELETE_LEVEL_FAILURE
  );
}

export function getvariationsinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_VARIATIONS_BEGIN,
    REDUX_TYPE_ENUMS.GET_VARIATIONS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_VARIATIONS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_VARIATIONS
  );
}

export function postvariationinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultPOSTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.POST_VARIATION_BEGIN,
    REDUX_TYPE_ENUMS.POST_VARIATION_SUCCESS,
    REDUX_TYPE_ENUMS.POST_VARIATION_FAILURE
  );
}

export function putvariationinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultPUTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.PUT_VARIATION_BEGIN,
    REDUX_TYPE_ENUMS.PUT_VARIATION_SUCCESS,
    REDUX_TYPE_ENUMS.PUT_VARIATION_FAILURE
  );
}

export function deletevariationinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultDELETEReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.DELETE_VARIATION_BEGIN,
    REDUX_TYPE_ENUMS.DELETE_VARIATION_SUCCESS,
    REDUX_TYPE_ENUMS.DELETE_VARIATION_FAILURE
  );
}

export function getswmssinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_SWMSS_BEGIN,
    REDUX_TYPE_ENUMS.GET_SWMSS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_SWMSS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_SWMSS
  );
}

export function postswmsinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultPOSTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.POST_SWMS_BEGIN,
    REDUX_TYPE_ENUMS.POST_SWMS_SUCCESS,
    REDUX_TYPE_ENUMS.POST_SWMS_FAILURE
  );
}

export function putswmwinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultPUTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.PUT_SWMS_BEGIN,
    REDUX_TYPE_ENUMS.PUT_SWMS_SUCCESS,
    REDUX_TYPE_ENUMS.PUT_SWMS_FAILURE
  );
}

export function deleteswmsinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultDELETEReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.DELETE_SWMS_BEGIN,
    REDUX_TYPE_ENUMS.DELETE_SWMS_SUCCESS,
    REDUX_TYPE_ENUMS.DELETE_SWMS_FAILURE
  );
}

export function geteventsinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_EVENTS_BEGIN,
    REDUX_TYPE_ENUMS.GET_EVENTS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_EVENTS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_EVENTS
  );
}

export function geteventinfo(state = initialGETState, action: AnyAction) {
  return defaultGETReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_EVENT_BEGIN,
    REDUX_TYPE_ENUMS.GET_EVENT_SUCCESS,
    REDUX_TYPE_ENUMS.GET_EVENT_FAILURE
  );
}

export function posteventinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultPOSTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.POST_EVENT_BEGIN,
    REDUX_TYPE_ENUMS.POST_EVENT_SUCCESS,
    REDUX_TYPE_ENUMS.POST_EVENT_FAILURE
  );
}

export function puteventinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultPUTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.PUT_EVENT_BEGIN,
    REDUX_TYPE_ENUMS.PUT_EVENT_SUCCESS,
    REDUX_TYPE_ENUMS.PUT_EVENT_FAILURE
  );
}

export function deleteeventinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultDELETEReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.DELETE_EVENT_BEGIN,
    REDUX_TYPE_ENUMS.DELETE_EVENT_SUCCESS,
    REDUX_TYPE_ENUMS.DELETE_EVENT_FAILURE
  );
}

export function getshiftsinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_SHIFTS_BEGIN,
    REDUX_TYPE_ENUMS.GET_SHIFTS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_SHIFTS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_SHIFTS
  );
}

export function deleteshiftinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultDELETEReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.DELETE_SHIFT_BEGIN,
    REDUX_TYPE_ENUMS.DELETE_SHIFT_SUCCESS,
    REDUX_TYPE_ENUMS.DELETE_SHIFT_FAILURE
  );
}

export function getchannelsinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_CHANNELS_BEGIN,
    REDUX_TYPE_ENUMS.GET_CHANNELS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_CHANNELS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_CHANNELS
  );
}

export function getchannelinfo(state = initialGETState, action: AnyAction) {
  return defaultGETReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_CHANNEL_BEGIN,
    REDUX_TYPE_ENUMS.GET_CHANNEL_SUCCESS,
    REDUX_TYPE_ENUMS.GET_CHANNEL_FAILURE
  );
}

export function postchannelinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultPOSTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.POST_CHANNEL_BEGIN,
    REDUX_TYPE_ENUMS.POST_CHANNEL_SUCCESS,
    REDUX_TYPE_ENUMS.POST_CHANNEL_FAILURE
  );
}

export function putchannelinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultPUTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.PUT_CHANNEL_BEGIN,
    REDUX_TYPE_ENUMS.PUT_CHANNEL_SUCCESS,
    REDUX_TYPE_ENUMS.PUT_CHANNEL_FAILURE
  );
}

export function deletechannelinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultDELETEReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.DELETE_CHANNEL_BEGIN,
    REDUX_TYPE_ENUMS.DELETE_CHANNEL_SUCCESS,
    REDUX_TYPE_ENUMS.DELETE_CHANNEL_FAILURE
  );
}

export function getmessagesinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_MESSAGES_BEGIN,
    REDUX_TYPE_ENUMS.GET_MESSAGES_SUCCESS,
    REDUX_TYPE_ENUMS.GET_MESSAGES_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_MESSAGES
  );
}

export function getmessageinfo(state = initialGETState, action: AnyAction) {
  return defaultGETReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_MESSAGE_BEGIN,
    REDUX_TYPE_ENUMS.GET_MESSAGE_SUCCESS,
    REDUX_TYPE_ENUMS.GET_MESSAGE_FAILURE
  );
}

export function postmessageinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultPOSTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.POST_MESSAGE_BEGIN,
    REDUX_TYPE_ENUMS.POST_MESSAGE_SUCCESS,
    REDUX_TYPE_ENUMS.POST_MESSAGE_FAILURE
  );
}

export function deletemessageinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultDELETEReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.DELETE_MESSAGE_BEGIN,
    REDUX_TYPE_ENUMS.DELETE_MESSAGE_SUCCESS,
    REDUX_TYPE_ENUMS.DELETE_MESSAGE_FAILURE
  );
}

export function getdmssinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultLISTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_DMSS_BEGIN,
    REDUX_TYPE_ENUMS.GET_DMSS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_DMSS_FAILURE,
    REDUX_TYPE_ENUMS.CLEAR_DMSS
  );
}

export function getdmsinfo(state = initialGETState, action: AnyAction) {
  return defaultGETReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.GET_DMS_BEGIN,
    REDUX_TYPE_ENUMS.GET_DMS_SUCCESS,
    REDUX_TYPE_ENUMS.GET_DMS_FAILURE
  );
}

export function postdmsinfo(
  state = initialLISTandPOSTState,
  action: AnyAction
) {
  return defaultPOSTReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.POST_DMS_BEGIN,
    REDUX_TYPE_ENUMS.POST_DMS_SUCCESS,
    REDUX_TYPE_ENUMS.POST_DMS_FAILURE
  );
}

export function deletedmsinfo(
  state = initialPUTandDELETEState,
  action: AnyAction
) {
  return defaultDELETEReducer(
    state,
    action,
    REDUX_TYPE_ENUMS.DELETE_DMS_BEGIN,
    REDUX_TYPE_ENUMS.DELETE_DMS_SUCCESS,
    REDUX_TYPE_ENUMS.DELETE_DMS_FAILURE
  );
}

const rootReducer = combineReducers({
  dummyinfo,
  getlogininfo,
  getuseridinfo,
  getuserinfo,
  getassocusersinfo,
  getunreadmsgsinfo,
  putunreadmsginfo,
  getunconfirmedsinfo,
  putunconfirmedinfo,
  getconfirmunknownsinfo,
  putconfirmunknowninfo,
  getassoceventssinfo,
  postpasswordinfo,
  putuserinfo,
  getjobsinfo,
  getjobinfo,
  postjobinfo,
  putjobinfo,
  deletejobinfo,
  getlevelsinfo,
  postlevelinfo,
  putlevelinfo,
  getvariationsinfo,
  postvariationinfo,
  putvariationinfo,
  getswmssinfo,
  postswmsinfo,
  putswmwinfo,
  geteventsinfo,
  geteventinfo,
  posteventinfo,
  puteventinfo,
  deleteeventinfo,
  getshiftsinfo,
  deleteshiftinfo,
  getchannelsinfo,
  getchannelinfo,
  postchannelinfo,
  putchannelinfo,
  deletechannelinfo,
  getmessagesinfo,
  getmessageinfo,
  postmessageinfo,
  deletemessageinfo,
  getdmssinfo,
  getdmsinfo,
  postdmsinfo,
  deletedmsinfo,
});

/* Having created reducers, it is time for the actions. While we create some standalone
ones, there are also patterns that we want to embrace. */

const dummyActions = {
  dummy,
};

function dummy() {
  return { type: typify(REDUX_TYPE_ENUMS.DUMMY_INFO) };
}

const getLoginBegin = () => ({
  type: typify(REDUX_TYPE_ENUMS.GET_LOGIN_BEGIN),
});

const getLoginSuccess = (data: any) => ({
  type: typify(REDUX_TYPE_ENUMS.GET_LOGIN_SUCCESS),
  payload: data,
});

const getLoginFailure = (error: any) => ({
  type: typify(REDUX_TYPE_ENUMS.GET_LOGIN_FAILURE),
  payload: error,
});

function getLoginAction(username: string, password: string) {
  return (
    dispatch: (
      arg0: (dispatch: (arg0: AnyAction) => void) => Promise<any>
    ) => Promise<any>
  ) => {
    dispatch(userService.getLogin(username, password)).then((obj) => {
      if (obj.access) {
        dispatch(userService.getUserId())
          .then((val) => {
            //      store.dispatch(connect(webSocketInt));
            dispatch(userService.getJobs(val.id));
            dispatch(userService.getUser(val.id)).then((otherval) => {
              let companyid = otherval.extraprofile.usercompany.toString();
              localStorage.setItem("companyid", companyid);
              dispatch(userService.getChannels(companyid));
            });
          })
          .then((obj) => {
            history.push("/jobs");
          });
      }
    });
  };
}

function getLogout() {
  userService.logout();
  //store.dispatch(disconnect());
  persistor.purge();
  history.push("/");
  return { type: typify(REDUX_TYPE_ENUMS.CLEAR_LOGIN) };
}

const getLoginActions = {
  getLoginBegin,
  getLoginSuccess,
  getLoginFailure,
  getLoginAction,
  getLogout,
};

/* This creates actions of the form (Begin Something / Succeed / Fail / Clear) */

function actionCreator(
  actBeginType: number,
  actSuccessType: number,
  actFailureType: number,
  actClearType?: number
) {
  const actBegin = () => ({
    type: typify(actBeginType),
  });

  const actSuccess = (data: any) => ({
    type: typify(actSuccessType),
    payload: data,
  });

  const actFailure = (error: any) => ({
    type: typify(actFailureType),
    payload: error,
  });

  if (actClearType) {
    const actClear = () => ({
      type: typify(actClearType),
    });
    return { actBegin, actSuccess, actFailure, actClear };
  } else {
    const actClear = () => ({ type: typify(REDUX_TYPE_ENUMS.DUMMY_INFO) });
    return { actBegin, actSuccess, actFailure, actClear };
  }
}

const getUserIdActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_USERID_BEGIN,
  REDUX_TYPE_ENUMS.GET_USERID_SUCCESS,
  REDUX_TYPE_ENUMS.GET_USERID_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_USERID
);

const getAssocUsersActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_ASSOCUSERS_BEGIN,
  REDUX_TYPE_ENUMS.GET_ASSOCUSERS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_ASSOCUSERS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_ASSOCUSERS
);

const getUnreadMsgsActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_UNREADMSGS_BEGIN,
  REDUX_TYPE_ENUMS.GET_UNREADMSGS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_UNREADMSGS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_UNREADMSGS
);

const putUnreadMsgActions = actionCreator(
  REDUX_TYPE_ENUMS.PUT_UNREADMSG_BEGIN,
  REDUX_TYPE_ENUMS.PUT_UNREADMSG_SUCCESS,
  REDUX_TYPE_ENUMS.PUT_UNREADMSG_FAILURE
);

const getUnconfirmedsActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_UNCONFIRMEDS_BEGIN,
  REDUX_TYPE_ENUMS.GET_UNCONFIRMEDS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_UNCONFIRMEDS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_UNCONFIRMEDS
);

const putUnconfirmedActions = actionCreator(
  REDUX_TYPE_ENUMS.PUT_UNCONFIRMED_BEGIN,
  REDUX_TYPE_ENUMS.PUT_UNCONFIRMED_SUCCESS,
  REDUX_TYPE_ENUMS.PUT_UNCONFIRMED_FAILURE
);

const getConfirmUnknownsActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_CONFIRMUNKNOWNS_BEGIN,
  REDUX_TYPE_ENUMS.GET_CONFIRMUNKNOWNS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_CONFIRMUNKNOWNS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_CONFIRMUNKNOWNS
);

const putConfirmUnknownActions = actionCreator(
  REDUX_TYPE_ENUMS.PUT_CONFIRMUNKNOWN_BEGIN,
  REDUX_TYPE_ENUMS.PUT_CONFIRMUNKNOWN_SUCCESS,
  REDUX_TYPE_ENUMS.PUT_CONFIRMUNKNOWN_FAILURE
);

const getAssocEventsActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_ASSOCEVENTS_BEGIN,
  REDUX_TYPE_ENUMS.GET_ASSOCEVENTS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_ASSOCEVENTS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_ASSOCEVENTS
);

const postPasswordActions = actionCreator(
  REDUX_TYPE_ENUMS.POST_JOB_BEGIN,
  REDUX_TYPE_ENUMS.POST_JOB_SUCCESS,
  REDUX_TYPE_ENUMS.POST_JOB_FAILURE
);

const putUserActions = actionCreator(
  REDUX_TYPE_ENUMS.PUT_USER_BEGIN,
  REDUX_TYPE_ENUMS.PUT_USER_SUCCESS,
  REDUX_TYPE_ENUMS.PUT_USER_FAILURE
);

const getJobsActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_JOBS_BEGIN,
  REDUX_TYPE_ENUMS.GET_JOBS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_JOBS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_JOBS
);

const getJobActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_JOB_BEGIN,
  REDUX_TYPE_ENUMS.GET_JOB_SUCCESS,
  REDUX_TYPE_ENUMS.GET_JOB_FAILURE
);

const postJobActions = actionCreator(
  REDUX_TYPE_ENUMS.POST_JOB_BEGIN,
  REDUX_TYPE_ENUMS.POST_JOB_SUCCESS,
  REDUX_TYPE_ENUMS.POST_JOB_FAILURE
);

const putJobActions = actionCreator(
  REDUX_TYPE_ENUMS.PUT_JOB_BEGIN,
  REDUX_TYPE_ENUMS.PUT_JOB_SUCCESS,
  REDUX_TYPE_ENUMS.PUT_JOB_FAILURE
);

const deleteJobActions = actionCreator(
  REDUX_TYPE_ENUMS.DELETE_JOB_BEGIN,
  REDUX_TYPE_ENUMS.DELETE_JOB_SUCCESS,
  REDUX_TYPE_ENUMS.DELETE_JOB_FAILURE
);

const getUserActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_USER_BEGIN,
  REDUX_TYPE_ENUMS.GET_USER_SUCCESS,
  REDUX_TYPE_ENUMS.GET_USER_FAILURE
);

const getLevelsActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_LEVELS_BEGIN,
  REDUX_TYPE_ENUMS.GET_LEVELS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_LEVELS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_LEVELS
);

const postLevelActions = actionCreator(
  REDUX_TYPE_ENUMS.POST_LEVEL_BEGIN,
  REDUX_TYPE_ENUMS.POST_LEVEL_SUCCESS,
  REDUX_TYPE_ENUMS.POST_LEVEL_FAILURE
);

const putLevelActions = actionCreator(
  REDUX_TYPE_ENUMS.PUT_LEVEL_BEGIN,
  REDUX_TYPE_ENUMS.PUT_LEVEL_SUCCESS,
  REDUX_TYPE_ENUMS.PUT_LEVEL_FAILURE
);

const deleteLevelActions = actionCreator(
  REDUX_TYPE_ENUMS.DELETE_LEVEL_BEGIN,
  REDUX_TYPE_ENUMS.DELETE_LEVEL_SUCCESS,
  REDUX_TYPE_ENUMS.DELETE_LEVEL_FAILURE
);

const getVariationsActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_VARIATIONS_BEGIN,
  REDUX_TYPE_ENUMS.GET_VARIATIONS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_VARIATIONS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_VARIATIONS
);

const postVariationActions = actionCreator(
  REDUX_TYPE_ENUMS.POST_VARIATION_BEGIN,
  REDUX_TYPE_ENUMS.POST_VARIATION_SUCCESS,
  REDUX_TYPE_ENUMS.POST_VARIATION_FAILURE
);

const putVariationActions = actionCreator(
  REDUX_TYPE_ENUMS.PUT_VARIATION_BEGIN,
  REDUX_TYPE_ENUMS.PUT_VARIATION_SUCCESS,
  REDUX_TYPE_ENUMS.PUT_VARIATION_FAILURE
);

const deleteVariationActions = actionCreator(
  REDUX_TYPE_ENUMS.DELETE_VARIATION_BEGIN,
  REDUX_TYPE_ENUMS.DELETE_VARIATION_SUCCESS,
  REDUX_TYPE_ENUMS.DELETE_VARIATION_FAILURE
);

const getSWMSsActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_SWMSS_BEGIN,
  REDUX_TYPE_ENUMS.GET_SWMSS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_SWMSS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_SWMSS
);

const postSWMSActions = actionCreator(
  REDUX_TYPE_ENUMS.POST_SWMS_BEGIN,
  REDUX_TYPE_ENUMS.POST_SWMS_SUCCESS,
  REDUX_TYPE_ENUMS.POST_SWMS_FAILURE
);

const putSWMSActions = actionCreator(
  REDUX_TYPE_ENUMS.PUT_SWMS_BEGIN,
  REDUX_TYPE_ENUMS.PUT_SWMS_SUCCESS,
  REDUX_TYPE_ENUMS.PUT_SWMS_FAILURE
);

const deleteSWMSActions = actionCreator(
  REDUX_TYPE_ENUMS.DELETE_SWMS_BEGIN,
  REDUX_TYPE_ENUMS.DELETE_SWMS_SUCCESS,
  REDUX_TYPE_ENUMS.DELETE_SWMS_FAILURE
);

const getEventsActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_EVENTS_BEGIN,
  REDUX_TYPE_ENUMS.GET_EVENTS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_EVENTS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_EVENTS
);

const getEventActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_EVENT_BEGIN,
  REDUX_TYPE_ENUMS.GET_EVENT_SUCCESS,
  REDUX_TYPE_ENUMS.GET_EVENT_FAILURE
);

const postEventActions = actionCreator(
  REDUX_TYPE_ENUMS.POST_EVENT_BEGIN,
  REDUX_TYPE_ENUMS.POST_EVENT_SUCCESS,
  REDUX_TYPE_ENUMS.POST_EVENT_FAILURE
);

const putEventActions = actionCreator(
  REDUX_TYPE_ENUMS.PUT_EVENT_BEGIN,
  REDUX_TYPE_ENUMS.PUT_EVENT_SUCCESS,
  REDUX_TYPE_ENUMS.PUT_EVENT_FAILURE
);

const deleteEventActions = actionCreator(
  REDUX_TYPE_ENUMS.DELETE_EVENT_BEGIN,
  REDUX_TYPE_ENUMS.DELETE_EVENT_SUCCESS,
  REDUX_TYPE_ENUMS.DELETE_EVENT_FAILURE
);

const getShiftsActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_SHIFTS_BEGIN,
  REDUX_TYPE_ENUMS.GET_SHIFTS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_SHIFTS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_SHIFTS
);

const deleteShiftActions = actionCreator(
  REDUX_TYPE_ENUMS.DELETE_SHIFT_BEGIN,
  REDUX_TYPE_ENUMS.DELETE_SHIFT_SUCCESS,
  REDUX_TYPE_ENUMS.DELETE_SHIFT_FAILURE
);

const getChannelsActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_CHANNELS_BEGIN,
  REDUX_TYPE_ENUMS.GET_CHANNELS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_CHANNELS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_CHANNELS
);

const getChannelActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_CHANNEL_BEGIN,
  REDUX_TYPE_ENUMS.GET_CHANNEL_SUCCESS,
  REDUX_TYPE_ENUMS.GET_CHANNEL_FAILURE
);

const postChannelActions = actionCreator(
  REDUX_TYPE_ENUMS.POST_CHANNEL_BEGIN,
  REDUX_TYPE_ENUMS.POST_CHANNEL_SUCCESS,
  REDUX_TYPE_ENUMS.POST_CHANNEL_FAILURE
);

const putChannelActions = actionCreator(
  REDUX_TYPE_ENUMS.PUT_CHANNEL_BEGIN,
  REDUX_TYPE_ENUMS.PUT_CHANNEL_SUCCESS,
  REDUX_TYPE_ENUMS.PUT_CHANNEL_FAILURE
);

const deleteChannelActions = actionCreator(
  REDUX_TYPE_ENUMS.DELETE_CHANNEL_BEGIN,
  REDUX_TYPE_ENUMS.DELETE_CHANNEL_SUCCESS,
  REDUX_TYPE_ENUMS.DELETE_CHANNEL_FAILURE
);

const getMessagesActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_MESSAGES_BEGIN,
  REDUX_TYPE_ENUMS.GET_MESSAGES_SUCCESS,
  REDUX_TYPE_ENUMS.GET_MESSAGES_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_MESSAGES
);

const getMessageActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_MESSAGE_BEGIN,
  REDUX_TYPE_ENUMS.GET_MESSAGE_SUCCESS,
  REDUX_TYPE_ENUMS.GET_MESSAGE_FAILURE
);

const postMessageActions = actionCreator(
  REDUX_TYPE_ENUMS.POST_MESSAGE_BEGIN,
  REDUX_TYPE_ENUMS.POST_MESSAGE_SUCCESS,
  REDUX_TYPE_ENUMS.POST_MESSAGE_FAILURE
);

const deleteMessageActions = actionCreator(
  REDUX_TYPE_ENUMS.DELETE_MESSAGE_BEGIN,
  REDUX_TYPE_ENUMS.DELETE_MESSAGE_SUCCESS,
  REDUX_TYPE_ENUMS.DELETE_MESSAGE_FAILURE
);

const getDMSsActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_DMSS_BEGIN,
  REDUX_TYPE_ENUMS.GET_DMSS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_DMSS_FAILURE,
  REDUX_TYPE_ENUMS.CLEAR_DMSS
);

const getDMSActions = actionCreator(
  REDUX_TYPE_ENUMS.GET_DMS_BEGIN,
  REDUX_TYPE_ENUMS.GET_DMS_SUCCESS,
  REDUX_TYPE_ENUMS.GET_DMS_FAILURE
);

const postDMSActions = actionCreator(
  REDUX_TYPE_ENUMS.POST_DMS_BEGIN,
  REDUX_TYPE_ENUMS.POST_DMS_SUCCESS,
  REDUX_TYPE_ENUMS.POST_DMS_FAILURE
);

const deleteDMSActions = actionCreator(
  REDUX_TYPE_ENUMS.DELETE_DMS_BEGIN,
  REDUX_TYPE_ENUMS.DELETE_DMS_SUCCESS,
  REDUX_TYPE_ENUMS.DELETE_DMS_FAILURE
);

/**
 * Generates the header and body for requests.
 * @param {string} method - The method - "GET", "POST", or so on - used.
 * @param {object} [body=null] - the request body as key value pair, if needed.
 * @param {string} [token=null] - the JWT token to pass on, if needed.
 * @return {object} An object of header and body ready to be passed to fetch.
 */
function genReqOptions(method: string, body?: any, token?: string | null) {
  let ourReqOptions = {
    method: method,
    body: body ? JSON.stringify(body) : undefined,
    headers: { Authorization: "", "Content-Type": "application/json" },
  };
  if (token) {
    ourReqOptions["headers"]["Authorization"] = "JWT " + token;
  }
  return ourReqOptions;
}

/**
 * Generates the header and body for form data requests.
 * @param {string} method - The method - "GET", "POST", or so on - used.
 * @param {object} body - the request body as key value pair.
 * @return {object} An object of header and body ready to be passed to fetch.
 */
function formdataReqOptions(method: string, body: any) {
  let formData = new FormData();
  for (var key in body) {
    if (body.hasOwnProperty(key)) {
      // Some fields allow multiple values for the same key. We assume keys are in "fieldname[]" form.
      if (key.includes("[]")) {
        let newkey = key.slice(0, -2);
        for (let i = 0; i < body[key].length; i++) {
          if (body[key][i].name) {
            formData.append(newkey, body[key][i], body[key][i].name); // File values.
          } else {
            formData.append(newkey, body[key][i]); // String values.
          }
        }
      } else {
        formData.append(key, body[key]);
      }
    }
  }
  let usertoken = localStorage.getItem("usertoken");
  return {
    method: method,
    body: formData,
    headers: { Authorization: "JWT " + usertoken },
  };
}

// Code to be called when 401 errors arise (generally when JWT tokens time out.)

function redirecttologin() {
  store.dispatch(getLoginActions.getLogout());
  store.dispatch(getUserIdActions.actClear());
  store.dispatch(getAssocUsersActions.actClear());
  store.dispatch(getUnreadMsgsActions.actClear());
  store.dispatch(getUnconfirmedsActions.actClear());
  store.dispatch(getConfirmUnknownsActions.actClear());
  history.push("/");
}

// Nice to overwrite the Error class to pass on status codes.

class HTTPError extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);
    this.name = "HTTPError";
    this.message = message;
    this.status = status;
  }
}

// This seems to be the best practice for handling errors while fetching responses
// from a server and putting the results into Redux. It is designed to work with
// code that fits the following pattern:
//
// function someRequest(someParameters) {
//   return dispatch => {
//     dispatch(someAction.someActionBegin());
//     return fetch(someURL, someParameters)
//       .then(handleErrorsBest)
//       .then(res => {
//         dispatch(someAction.someActionSuccess(res));
//         return res;
//       })
//       .catch(error => dispatch(someAction.someActionFailure(serializeError(error))));
//   };
// }
// (a) If successful getting something from a server, expect JSON, convert it as
// such, and return it back to the caller.
// (b) If you have a 401 error, redirect to the login window.
// (c) Any other situation, throw an error, preferably with the original
// response text. If the server is unavailable, then an Error will be thrown as
// well. If the response can't be converted to JSON, you get an error as well!
//
// This is based on Jason Watmore's handleResponse function here:
// https://jasonwatmore.com/post/2017/09/16/react-redux-user-registration-and-login-tutorial-example
// However, I tweaked it so that meaningful data would be passed back on success and failure.
// Not only that, but the meaningful data would be available in the Redux store AND the Redux Dev
// Tools add-on for Firefox. (The serializeError method assists with the later.)

function handleErrorsBest(response: {
  text: () => Promise<string>;
  ok: any;
  status: number;
}) {
  return response.text().then((text: string) => {
    if (!response.ok) {
      if (response.status === 401) {
        redirecttologin();
      }
      throw new HTTPError(text, response.status);
    }

    // Some response texts are empty, such as posting password change or deleting resources. We need
    // to handle those as well.

    if (text.length === 0) {
      return {};
    } else {
      return JSON.parse(text);
    }
  });
}

function getLogin(username: string, password: string) {
  const requestOptions = genReqOptions(methodify(HTTP_METHODS.POST), {
    username: username,
    password: password,
  });
  return async (dispatch: (arg0: AnyAction) => void) => {
    dispatch(getLoginActions.getLoginBegin());
    try {
      const response = await fetch(
        `${domainStart}/v0/jwt/create`,
        requestOptions
      );
      const json = await handleErrorsBest(response);
      localStorage.setItem("usertoken", json.access);
      dispatch(getLoginActions.getLoginSuccess(json));
      return json;
    } catch (error) {
      return dispatch(getLoginActions.getLoginFailure(serializeError(error)));
    }
  };
}

function logout() {
  localStorage.removeItem("usertoken");
  localStorage.removeItem("userid");
  localStorage.removeItem("companyid");
}

function getUserId() {
  return async (dispatch: (arg0: AnyAction) => void) => {
    let usertoken = localStorage.getItem("usertoken");
    const requestOptions = genReqOptions(
      methodify(HTTP_METHODS.GET),
      null,
      usertoken
    );
    dispatch(getUserIdActions.actBegin());
    return fetch(`${domainStart}/v0/me`, requestOptions)
      .then(handleErrorsBest)
      .then((json) => {
        localStorage.setItem("userid", json.id.toString());
        dispatch(getUserIdActions.actSuccess(json));
        return json;
      })
      .catch((error) =>
        dispatch(getUserIdActions.actFailure(serializeError(error)))
      );
  };
}

/* From henceforth, all user services can fit a "generics" pattern. */

interface ActionTriune {
  actBegin: () => {
    type: string;
  };

  actSuccess: (
    data: any
  ) => {
    type: string;
    payload: any;
  };

  actFailure: (
    error: any
  ) => {
    type: string;
    payload: any;
  };

  actClear: () => {
    type: string;
  };
}

function genericUserService(
  requestURL: string,
  requestMethodType: HTTP_METHODS,
  requestTriune: ActionTriune,
  requestData: any
) {
  return async (dispatch: (arg0: AnyAction) => void) => {
    let requestMethod = methodify(requestMethodType);
    const requestOptions =
      requestMethod === methodify(HTTP_METHODS.POST) ||
      requestMethod === methodify(HTTP_METHODS.PUT)
        ? formdataReqOptions(requestMethod, requestData)
        : genReqOptions(
            requestMethod,
            requestData,
            localStorage.getItem("usertoken")
          );
    dispatch(requestTriune.actBegin());
    return fetch(requestURL, requestOptions)
      .then(handleErrorsBest)
      .then((json) => {
        dispatch(requestTriune.actSuccess(json));
        return json;
      })
      .catch((error) =>
        dispatch(requestTriune.actFailure(serializeError(error)))
      );
  };
}

function getUser(userid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/`,
    HTTP_METHODS.GET,
    getUserActions,
    null
  );
}

function getAssocUsers(userid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/assocusers/`,
    HTTP_METHODS.GET,
    getAssocUsersActions,
    null
  );
}

function getUnreadMsgs(userid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/unreadmsgs/`,
    HTTP_METHODS.GET,
    getUnreadMsgsActions,
    null
  );
}

function putUnreadMsg(userid: string, messageid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/unreadmsgs/${messageid}/`,
    HTTP_METHODS.PUT,
    putUnreadMsgActions,
    {}
  );
}

function getUnconfirmeds(userid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/unconfirmed/`,
    HTTP_METHODS.GET,
    getUnconfirmedsActions,
    null
  );
}

function putUnconfirmed(userid: string, messageid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/unconfirmed/${messageid}/`,
    HTTP_METHODS.PUT,
    putUnconfirmedActions,
    {}
  );
}

function getConfirmUnknowns(userid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/confirmunknown/`,
    HTTP_METHODS.GET,
    getConfirmUnknownsActions,
    null
  );
}

function putConfirmUnknown(userid: string, messageid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/confirmunknown/${messageid}/`,
    HTTP_METHODS.PUT,
    putConfirmUnknownActions,
    {}
  );
}

function getAssocEvents(
  userid: string,
  endafteris: string,
  startbeforeis: string
) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/assocevents/?endafteris=${endafteris}&startbeforeis=${startbeforeis}`,
    HTTP_METHODS.GET,
    getAssocEventsActions,
    null
  );
}

function postPassword(passworddata: any) {
  return genericUserService(
    `${domainStart}/v0/password/`,
    HTTP_METHODS.POST,
    postPasswordActions,
    passworddata
  );
}

function putUser(userid: string, userdata: any) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/`,
    HTTP_METHODS.PUT,
    putUserActions,
    userdata
  );
}

function getJobs(userid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/`,
    HTTP_METHODS.GET,
    getJobsActions,
    null
  );
}

function getJob(userid: string, jobid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}`,
    HTTP_METHODS.GET,
    getJobActions,
    null
  );
}

function postJob(userid: string, jobdata: any) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/`,
    HTTP_METHODS.POST,
    postJobActions,
    jobdata
  );
}

function putJob(userid: string, jobid: string, jobdata: any) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/`,
    HTTP_METHODS.PUT,
    putJobActions,
    jobdata
  );
}

function deleteJob(userid: string, jobid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}`,
    HTTP_METHODS.DELETE,
    deleteJobActions,
    null
  );
}

function getLevels(userid: string, jobid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/joblevels/`,
    HTTP_METHODS.GET,
    getLevelsActions,
    null
  );
}

function postLevel(userid: string, jobid: string, leveldata: any) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/joblevels/`,
    HTTP_METHODS.POST,
    postLevelActions,
    leveldata
  );
}

function putLevel(
  userid: string,
  jobid: string,
  levelid: string,
  leveldata: any
) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/joblevels/${levelid}/`,
    HTTP_METHODS.PUT,
    putLevelActions,
    leveldata
  );
}

function deleteLevel(userid: string, jobid: string, levelid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/joblevels/${levelid}`,
    HTTP_METHODS.DELETE,
    deleteLevelActions,
    null
  );
}

function getVariations(userid: string, jobid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/variations/`,
    HTTP_METHODS.GET,
    getVariationsActions,
    null
  );
}

function postVariation(userid: string, jobid: string, variationdata: any) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/variations/`,
    HTTP_METHODS.POST,
    postVariationActions,
    variationdata
  );
}

function putVariation(
  userid: string,
  jobid: string,
  variationid: string,
  variationdata: any
) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/variations/${variationid}/`,
    HTTP_METHODS.PUT,
    putVariationActions,
    variationdata
  );
}

function deleteVariation(userid: string, jobid: string, variationid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/variations/${variationid}`,
    HTTP_METHODS.DELETE,
    deleteVariationActions,
    null
  );
}

function getSWMSs(userid: string, jobid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/swms/`,
    HTTP_METHODS.GET,
    getSWMSsActions,
    null
  );
}

function postSWMS(userid: string, jobid: string, swmsdata: any) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/swms/`,
    HTTP_METHODS.POST,
    postSWMSActions,
    swmsdata
  );
}

function putSWMS(userid: string, jobid: string, swmsid: string, swmsdata: any) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/swms/${swmsid}/`,
    HTTP_METHODS.PUT,
    putSWMSActions,
    swmsdata
  );
}

function deleteSWMS(userid: string, jobid: string, swmsid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/swms/${swmsid}`,
    HTTP_METHODS.DELETE,
    deleteSWMSActions,
    null
  );
}

function getShifts(userid: string, jobid: string, eventid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/events/${eventid}/shifts/`,
    HTTP_METHODS.GET,
    getShiftsActions,
    null
  );
}

function deleteShift(
  userid: string,
  jobid: string,
  eventid: string,
  shiftid: string
) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/events/${eventid}/shifts/${shiftid}`,
    HTTP_METHODS.DELETE,
    deleteShiftActions,
    null
  );
}

function getEvents(userid: string, jobid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/events/`,
    HTTP_METHODS.GET,
    getEventsActions,
    null
  );
}

function postEvent(userid: string, jobid: string, eventdata: any) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/events/`,
    HTTP_METHODS.POST,
    postEventActions,
    eventdata
  );
}

function getEvent(userid: string, jobid: string, eventid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/events/${eventid}`,
    HTTP_METHODS.GET,
    getEventActions,
    null
  );
}

function putEvent(
  userid: string,
  jobid: string,
  eventid: string,
  eventdata: any
) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/events/${eventid}/`,
    HTTP_METHODS.PUT,
    putEventActions,
    eventdata
  );
}

function deleteEvent(userid: string, jobid: string, eventid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/jobs/${jobid}/events/${eventid}`,
    HTTP_METHODS.DELETE,
    deleteEventActions,
    null
  );
}

function getChannels(companyid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/usercompanies/${companyid}/channels/`,
    HTTP_METHODS.GET,
    getChannelsActions,
    null
  );
}

function postChannel(companyid: string, channeldata: any) {
  return genericUserService(
    `${domainStart}/v0/scafapp/usercompanies/${companyid}/channels/`,
    HTTP_METHODS.POST,
    postChannelActions,
    channeldata
  );
}

function getChannel(companyid: string, channelid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/usercompanies/${companyid}/channels/${channelid}`,
    HTTP_METHODS.GET,
    getChannelActions,
    null
  );
}

function putChannel(companyid: string, channelid: string, channeldata: any) {
  return genericUserService(
    `${domainStart}/v0/scafapp/usercompanies/${companyid}/channels/${channelid}/`,
    HTTP_METHODS.PUT,
    putChannelActions,
    channeldata
  );
}

function deleteChannel(companyid: string, channelid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/usercompanies/${companyid}/channels/${channelid}`,
    HTTP_METHODS.DELETE,
    deleteChannelActions,
    null
  );
}

function getMessages(companyid: string, channelid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/usercompanies/${companyid}/channels/${channelid}/messages/`,
    HTTP_METHODS.GET,
    getMessagesActions,
    null
  );
}

function postMessage(companyid: string, channelid: string, messagedata: any) {
  return genericUserService(
    `${domainStart}/v0/scafapp/usercompanies/${companyid}/channels/${channelid}/messages/`,
    HTTP_METHODS.POST,
    postMessageActions,
    messagedata
  );
}

function getMessage(companyid: string, channelid: string, messageid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/usercompanies/${companyid}/channels/${channelid}/messages/${messageid}/`,
    HTTP_METHODS.GET,
    getMessageActions,
    null
  );
}

function deleteMessage(
  companyid: string,
  channelid: string,
  messageid: string
) {
  return genericUserService(
    `${domainStart}/v0/scafapp/usercompanies/${companyid}/channels/${channelid}/messages/${messageid}/`,
    HTTP_METHODS.DELETE,
    deleteMessageActions,
    null
  );
}

function getDMSs(userid: string, assocuserid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/assocusers/${assocuserid}/dmss/`,
    HTTP_METHODS.GET,
    getDMSsActions,
    null
  );
}

function postDMS(userid: string, assocuserid: string, dmsdata: any) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/assocusers/${assocuserid}/dmss/`,
    HTTP_METHODS.POST,
    postDMSActions,
    dmsdata
  );
}

function getDMS(userid: string, assocuserid: string, dmsid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/assocusers/${assocuserid}/dmss/${dmsid}/`,
    HTTP_METHODS.GET,
    getDMSActions,
    null
  );
}

function deleteDMS(userid: string, assocuserid: string, dmsid: string) {
  return genericUserService(
    `${domainStart}/v0/scafapp/users/${userid}/assocusers/${assocuserid}/dmss/${dmsid}/`,
    HTTP_METHODS.DELETE,
    deleteDMSActions,
    null
  );
}

const userService = {
  domainStart,
  getLogin,
  getUserId,
  getJobs,
  getJob,
  getUser,
  getAssocUsers,
  getUnreadMsgs,
  putUnreadMsg,
  getUnconfirmeds,
  putUnconfirmed,
  getConfirmUnknowns,
  putConfirmUnknown,
  getAssocEvents,
  postPassword,
  putUser,
  logout,
  postJob,
  putJob,
  deleteJob,
  getLevels,
  postLevel,
  putLevel,
  deleteLevel,
  getVariations,
  postVariation,
  putVariation,
  deleteVariation,
  getSWMSs,
  postSWMS,
  putSWMS,
  deleteSWMS,
  getEvents,
  getEvent,
  postEvent,
  putEvent,
  deleteEvent,
  getShifts,
  deleteShift,
  getChannels,
  getChannel,
  postChannel,
  putChannel,
  deleteChannel,
  getMessages,
  getMessage,
  postMessage,
  deleteMessage,
  getDMSs,
  getDMS,
  postDMS,
  deleteDMS,
};

type RootState = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    "dummyinfo",
    "getlogininfo",
    "getuseridinfo",
    "getusersinfo",
    "getuserinfo",
    "getchannelsinfo",
    "getassocusersinfo",
    "getjobsinfo",
    "postuserinfo",
    "putuserinfo",
    "activateuserinfo",
    "resenduserinfo",
    "postpasswordinfo",
    "resetpasswordinfo",
    "confirmpasswordinfo",
  ],
};
const pReducer = persistReducer<RootState>(persistConfig, rootReducer);

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const store = createStore(
  pReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware, reduxWebsocketMiddleware))
);

const persistor = persistStore(store);

export {
  rootReducer,
  persistor,
  store,
  userService,
  getLoginActions,
  getUserIdActions,
  getAssocUsersActions,
  getJobsActions,
  dummyActions,
};
