import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import LogoutPage from "./screens/LogoutPage";
import LoginPage from "./screens/LoginPage";
import PrivatePage from "./screens/PrivatePage";
import SchedulePage from "./screens/SchedulePage";
import asyncComponent from "./components/AsyncComponent";
import { store, dummyActions } from "./reduxandotherstuff/arhs";
import { connect } from "react-redux";

import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const AsyncNoMatchPage = asyncComponent(() => import("./screens/NoMatchPage"));
const AsyncJobDetailsPage = asyncComponent(
  () => import("./screens/JobDetailsPage")
);
const AsyncSettingsPage = asyncComponent(
  () => import("./screens/SettingsPage")
);
const AsyncNewsfeedPage = asyncComponent(
  () => import("./screens/NewsfeedPage")
);
const AsyncCalendarPage = asyncComponent(
  () => import("./screens/CalendarPage")
);
const AsyncEventDetailsPage = asyncComponent(
  () => import("./screens/EventDetailsPage")
);

const AsyncNotificationsPage = asyncComponent(
  () => import("./screens/NotificationsPage")
);

interface AuthRoot {
  redirector: string;
  component: React.ComponentType<any>;
  path: string;
}

const PrivateRoute = ({
  redirector: Redirector,
  component: Component,
  ...rest
}: AuthRoot) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("usertoken") ? (
        <Component {...props} />
      ) : (
        <Redirect to={Redirector} />
      )
    }
  />
);

const NeverPrivateRoute: React.ComponentType<any> = ({
  redirector: Redirector,
  component: Component,
  ...rest
}: AuthRoot) => (
  <Route
    {...rest}
    render={(props) =>
      !localStorage.getItem("usertoken") ? (
        <Component {...props} />
      ) : (
        <Redirect to={Redirector} />
      )
    }
  />
);

interface Props {
  dummyinfo: any;
}

class App extends Component<Props> {
  constructor(props: Props) {
    super(props);

    history.listen((location) => {
      store.dispatch<any>(dummyActions.dummy());
    });
  }
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <PrivateRoute
              path="/logout"
              redirector="/"
              component={LogoutPage}
            />
            <NeverPrivateRoute
              exact
              path="/"
              redirector="/jobs"
              component={LoginPage}
            />
            <PrivateRoute
              path="/schedule/jobdetail/:jobid/event/:id"
              redirector="/"
              component={AsyncEventDetailsPage}
            />
            <PrivateRoute
              path="/schedule/calendar/:year"
              redirector="/"
              component={AsyncCalendarPage}
            />
            <PrivateRoute
              path="/schedule/:weekstart"
              redirector="/"
              component={SchedulePage}
            />
            <PrivateRoute
              path="/schedule"
              redirector="/"
              component={SchedulePage}
            />
            <PrivateRoute
              path="/newsfeed/channel/:channelid"
              redirector="/"
              component={AsyncNewsfeedPage}
            />
            <PrivateRoute
              path="/newsfeed/dms/:dmsid"
              redirector="/"
              component={AsyncNewsfeedPage}
            />
            <PrivateRoute
              path="/newsfeed"
              redirector="/"
              component={AsyncNewsfeedPage}
            />
            <PrivateRoute
              path="/notifications"
              redirector="/"
              component={AsyncNotificationsPage}
            />

            <PrivateRoute
              path="/profile/:id"
              redirector="/"
              component={AsyncSettingsPage}
            />
            <PrivateRoute
              path="/settings"
              redirector="/"
              component={AsyncSettingsPage}
            />
            <PrivateRoute
              path="/jobs/:id"
              redirector="/"
              component={AsyncJobDetailsPage}
            />
            <PrivateRoute path="/jobs" redirector="/" component={PrivatePage} />
            <Route component={AsyncNoMatchPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state: Props) {
  const { dummyinfo } = state;
  return {
    dummyinfo,
  };
}

export default connect(mapStateToProps)(App);
